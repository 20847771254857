import {
    Direction,
    directionOrientation,
    ElementHelper,
    invertDirection,
    keySort,
    Orientation,
    ROUTE_BRANCH_POINT_TERMINAL_UID,
    vec2,
} from "@buildwithflux/core";
import {IElementData, IVector2, orientationOfVector} from "@buildwithflux/models";
import {isEqual, uniq} from "lodash";

import {IDerivedElementData} from "../scene_subjects/Element/ElementSubject";

import Wiring, {WireConnectionPoint, WireProjectionAlgorithm, WireProjectionVertex} from "./Wiring";
import Vertex from "./Vertex";

// Big enough to avoid the typical pin label size:
const PROJECTION_AVOID_THRESHOLD = 25;

const {HORIZONTAL, VERTICAL} = Orientation;

const DirectionVectors: Record<Direction, IVector2> = {
    up: {x: 0, y: 1},
    down: {x: 0, y: -1},
    left: {x: -1, y: 0},
    right: {x: 1, y: 0},
};

function directionOfVector(vector: IVector2): Direction {
    return Object.entries(DirectionVectors).sort(keySort(([_d, v]) => -vec2.dot(v, vector)))[0]![0] as Direction;
}

/** Work out which 2 directions are facing towards the other point.
 * Note: also consider it "towards" if exactly equal in one or both axes. This is
 * what we want in all cases (otherwise you get weird circuitous wires when the user
 * simply wants a straight line). Be aware of this if you use this logic for other
 * purposes where that may not apply!
 */
function getTowardsDirections(from: IVector2, to: IVector2): Direction[] {
    const towardsDirections: Direction[] = [];
    if (from.x <= to.x) towardsDirections.push("right");
    if (from.x >= to.x) towardsDirections.push("left");
    if (from.y <= to.y) towardsDirections.push("up");
    if (from.y >= to.y) towardsDirections.push("down");
    return towardsDirections;
}

export class WireProjectionSmart implements WireProjectionAlgorithm {
    public constructor(
        private routeManager: Wiring,
        private getElementData: (elementUid: string) => IElementData | undefined,
        private getDerivedElementData?: (elementUid: string) => IDerivedElementData | undefined,
        private startOrientation?: Orientation,
    ) {}

    public project(from: WireConnectionPoint, to: WireConnectionPoint): WireProjectionVertex[] {
        const directionFrom = this.preferredWireDirection(from, to, {constrain: this.startOrientation});
        const directionTo = this.preferredWireDirection(to, from, {
            otherDirection: directionFrom,
        });

        const fromIsTowards = getTowardsDirections(from.position, to.position).includes(directionFrom);
        const toIsTowards = getTowardsDirections(to.position, from.position).includes(directionTo);

        let fromOrientation = directionOrientation(directionFrom);
        let toOrientation = directionOrientation(directionTo);
        let perpendicular = fromOrientation !== directionOrientation(directionTo);
        const axisAligned = from.position.y === to.position.y || from.position.x === to.position.x;

        const wireStart: WireProjectionVertex[] = [{position: from.position}];
        const wireEnd: WireProjectionVertex[] = [];
        if (!isEqual(from.position, to.position) && !axisAligned) {
            // If `from` is facing strictly away, add a min-distance segment in that direction before turning
            if (!fromIsTowards) {
                if (from.terminal) {
                    // Only place a min-distance segment if `from` is a terminal. When wiring from plain wires,
                    // (especially when moving backwards from the endpoint) we don't want the extra pointless
                    // segment, even if WiringControls.fromOrientation forces this.startOrientation in that direction.
                    // However, still switch orientation below or we'll be drawing over the existing wire.
                    // TODO might need a bit more nuance there, eg only make the exception for single wires, or
                    // make WiringControls.fromOrientation smarter.
                    const newPosition = vec2
                        .chain(DirectionVectors[directionFrom])
                        .scale(PROJECTION_AVOID_THRESHOLD)
                        .add(from.position)
                        .xy();
                    wireStart.push({direction: fromOrientation, position: newPosition});
                    from = {position: newPosition};
                }
                fromOrientation = fromOrientation === HORIZONTAL ? VERTICAL : HORIZONTAL;
                perpendicular = !perpendicular;
            }

            // Similarly if `to` is facing strictly away.
            if (!toIsTowards) {
                // See above in `from` block re only placing this segment for terminals.
                if (to.terminal) {
                    const newPosition = vec2
                        .chain(DirectionVectors[directionTo])
                        .scale(PROJECTION_AVOID_THRESHOLD)
                        .add(to.position)
                        .xy();
                    wireEnd.push({direction: toOrientation, position: to.position});
                    to = {position: newPosition};
                }
                toOrientation = toOrientation === HORIZONTAL ? VERTICAL : HORIZONTAL;
                perpendicular = !perpendicular;
            }
        }

        // Now that we've ensured the away-facing segments are dealt with, the remainder of the
        // problem reduces to the case where `from` and `to` are both facing towards each other.
        // They're either aligned or perpendicular, so we draw an |-shape or L-shape or an S-shape.
        if (axisAligned) {
            // Normalization actually cleans up extra segments if we were to use an L or S shape here,
            // but not without creating different vertices in the process, which may cause less
            // stable UI, eg a segment that was selected may become needlessly deslected.
            wireStart.push({direction: toOrientation, position: to.position});
        } else if (perpendicular) {
            // Classic 2-segment L-shaped elbow
            const elbow = {
                x: fromOrientation === HORIZONTAL ? to.position.x : from.position.x,
                y: fromOrientation === HORIZONTAL ? from.position.y : to.position.y,
            };
            wireStart.push(
                ...[
                    {direction: fromOrientation, position: elbow},
                    {direction: toOrientation, position: to.position},
                ],
            );
        } else {
            // 3-segment S-bend
            wireStart.push(
                ...[
                    {
                        direction: fromOrientation,
                        position:
                            fromOrientation === HORIZONTAL
                                ? {x: (from.position.x + to.position.x) / 2, y: from.position.y}
                                : {x: from.position.x, y: (from.position.y + to.position.y) / 2},
                    },
                    {
                        direction: fromOrientation === HORIZONTAL ? VERTICAL : HORIZONTAL,
                        position:
                            fromOrientation === HORIZONTAL
                                ? {x: (from.position.x + to.position.x) / 2, y: to.position.y}
                                : {x: to.position.x, y: (from.position.y + to.position.y) / 2},
                    },
                    {direction: toOrientation, position: to.position},
                ],
            );
        }
        return [...wireStart, ...wireEnd];
    }

    public preferredWireDirection(
        at: WireConnectionPoint,
        other: WireConnectionPoint,
        opts: {constrain?: Orientation; otherDirection?: Direction} = {},
    ): Direction {
        let preferred: Direction | undefined;

        let available = this.availableWireDirections(at);
        if (opts.constrain) {
            // `constrain` is mainly for setting the initial wire orientation, which is also
            // what gets flipped when 'f' is pressed. Might be used for more in future.
            available = available.filter((d) => directionOrientation(d) === opts.constrain);
        }
        // Note: `occupied` is not necessarily just the inverse of `available`: we provide additional
        // factors to constrain `available`, so this needs to be an independent set.
        const occupied = this.occupiedWireDirections(at);
        const towardsDirections: Direction[] = getTowardsDirections(at.position, other.position);
        const pin = this.pinDirectionAt(at);

        // 1. If "aligned with pin" is available, pick that.
        if (pin && available.includes(invertDirection(pin))) {
            return invertDirection(pin);
        }

        // 2. If "towards other, inline with an existing wire" is available, pick that.
        preferred = available.filter((d) => towardsDirections.includes(d) && occupied.includes(invertDirection(d)))[0];
        if (preferred) {
            return preferred;
        }

        // 3. If "towards other" is available, pick that (prefer over "inline, pointing away"
        // below because "inline, pointing away" is just extending a wire already going that
        // direction, and the end result has the same shape as this; so avoid adding unnecessary
        // wire).
        const delta = vec2.sub(other.position, at.position);
        const towardsOther = available.filter((d) => towardsDirections.includes(d));
        // Sort by the direction that is most aligned with the trajectory, so as to produce
        // deterministic results when the first orientation is chosen.
        towardsOther.sort(keySort((d) => -vec2.dot(DirectionVectors[d], delta)));
        if (opts.otherDirection) {
            // If the other direction has already been chosen, then: if other is facing towards,
            // prefer a perpendicular orientation; if other facing away, prefer an aligned orientation.
            // These just work out to give the simplest overall shapes (simple L- or U-shapes without
            // an extra zigzag).
            const otherIsTowards = getTowardsDirections(other.position, at.position).includes(opts.otherDirection);
            towardsOther.sort(
                keySort(
                    (d) =>
                        (otherIsTowards ? 1 : -1) *
                        Number(directionOrientation(d) === directionOrientation(opts.otherDirection!)),
                ),
            );
        }
        if (towardsOther[0]) {
            return towardsOther[0];
        }

        // 4. If "inline with an existing wire (pointing away)" is available, pick that.
        preferred = available.filter((d) => occupied.includes(invertDirection(d)))[0];
        if (preferred) {
            return preferred;
        }

        // 5. If any other options are available, pick one of them. (This won't normally happen,
        // but could if the start and end positions are exactly aligned horizontally or vertically)
        if (available[0]) {
            return available[0];
        }

        // 6. If nothing above produced results, meaning no available directions, pick the direction
        // most aligned with the trajectory (eg initial mousemove direction).
        return directionOfVector(delta);
    }

    private pinDirectionAt(at: Pick<WireConnectionPoint, "terminal">) {
        if (!(at.terminal && at.terminal.terminal_uid !== ROUTE_BRANCH_POINT_TERMINAL_UID)) {
            return undefined;
        }

        let terminalDirection: Direction | undefined = undefined;
        let derivedElementData: Pick<IDerivedElementData, "absolutePinLocations"> | undefined =
            this.getDerivedElementData?.(at.terminal.element_uid);
        if (!derivedElementData) {
            const elementData = this.getElementData(at.terminal.element_uid);
            if (!elementData) return undefined;
            derivedElementData = {absolutePinLocations: ElementHelper.getAbsoluteTerminalPlacements(elementData)};
        }
        terminalDirection = derivedElementData.absolutePinLocations[at.terminal.terminal_uid]?.direction;
        // Terminal direction w.r.t. the element is the opposite of the wire direction w.r.t. the terminal, so invert.
        return terminalDirection ? invertDirection(terminalDirection) : undefined;
    }

    private occupiedWireDirections(at: WireConnectionPoint) {
        let directions: Direction[] = [];
        if (at.vertex) {
            const position = {...at.vertex.position};
            // Get direction of connections to this vertex and to any of its vertices that are in
            // the exact same position as it, excluding zero-length connections.
            // Note: normalization now eliminates these colocated vertices. We could potentially
            // remove this extra step here. Leaving it for now to be safe.
            const colocatedVertices = this.routeManager.getAllConnectedVertices(at.vertex, (v) => {
                return vec2.eq(v.position, position);
            });
            for (const vertex of colocatedVertices) {
                directions.push(
                    ...vertex.connectedVertices
                        .filter((c) => !colocatedVertices.includes(c.vertex))
                        .map((c) => directionOfVector(vec2.sub(c.vertex.position, position))),
                );
                const pinDirection = vertex.terminal ? this.pinDirectionAt({terminal: vertex.terminal}) : undefined;
                if (pinDirection) {
                    directions.push(pinDirection);
                }
            }
        } else if (at.terminal) {
            // Add pin direction if we're connected to a terminal (and we didn't already handle it via a vertex above)
            const pinDirection = this.pinDirectionAt(at);
            if (pinDirection) {
                directions.push(pinDirection);
            }
        } else if (at.segment) {
            const vertices = this.routeManager.getVertices(at.segment) as [Vertex, Vertex];
            if (vertices.length !== 2)
                throw new Error(`Missing vertices for segment ${at.segment[0]}:${at.segment[1]}`);
            const orientation = orientationOfVector(vec2.sub(vertices[0].position, vertices[1].position));
            directions = orientation === VERTICAL ? ["up", "down"] : ["left", "right"];
        }
        return uniq(directions).sort();
    }

    private availableWireDirections(at: WireConnectionPoint): Direction[] {
        const occupiedDirections = this.occupiedWireDirections(at);
        const allDirections: Direction[] = ["up", "down", "left", "right"];
        return allDirections.filter((d) => !occupiedDirections.includes(d)).sort();
    }
}
