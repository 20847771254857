import {defaultPropertyDefinitions} from "@buildwithflux/constants";
import {
    addPcbNodes,
    AnyPcbNode,
    applyActionRecordsToDoc,
    AutoLayoutHelpers,
    documentConfigUid,
    documentConfigWithIds,
    DocumentReducerError,
    EditorModes,
    ElementHelper,
    GENESIS_TERMINAL_PART_UID,
    getProperty,
    hasTopLevelContainerNode,
    headVersionName,
    IDocumentConfigDataWithoutIds,
    IUserData,
    MissingArgumentReducerError,
    MissingFieldReducerError,
    PcbLayoutRuleCompiler,
    PcbNodeHelper,
    PcbNodesMap,
    PcbNodeTypes,
    PcbTreeManager,
    removeNodesAndDescendants,
    revertActionRecords,
    RuleKey,
    setElementsAndGenerateNodes,
    toggleNodesWithImportant,
    withHistoryUpdate,
} from "@buildwithflux/core";
import {deepCopy} from "@buildwithflux/firestore-compatibility-layer";
import {
    createNodeFromVirtualNode,
    generateUserAccessListForDocument,
    getSubTreeUids,
    IDocumentData,
    IPropertyData,
    ISubDocumentData,
} from "@buildwithflux/models";
import {guid, isDevEnv} from "@buildwithflux/shared";
import {isAnyOf} from "@reduxjs/toolkit";
import {applyChange} from "deep-diff";
import {applyPatches, enablePatches, setAutoFreeze} from "immer";
import {difference, intersectionWith, isEqual, isPlainObject, omit, values} from "lodash";
import {AnyAction} from "redux";

import {currentAgentIsBot} from "../../../helpers/isBot";
import {costBasedAutoWireStrategy} from "../../../modules/auto_wire/cost_based";
import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";
import Document from "../../../modules/storage_engine/helpers/Document";
import {DocumentStorageHelper} from "../../../modules/storage_engine/helpers/DocumentStorageHelper";
import {PartStorageHelper} from "../../../modules/storage_engine/helpers/PartStorageHelper";
import {EnrichedConnectPinsCommand} from "../../../modules/suggestions/enrichment";
import {applyCommands} from "../../../modules/suggestions/thunks";
import {ANON_ROLE_UID} from "../../../resources/constants/anonRoleUid";
import ensureRouteVertexState from "../ensureRouteVertexState";
import {
    startReceivingLatestDraftsForPartUid,
    stopReceivingLatestDraftsForPartUid,
} from "../parts/publishing/drafts/actions";

import * as Actions from "./actions";
import {IDocumentReduxAction, PayloadAction, PayloadProps} from "./actions.types";
import DocumentPatchManager from "./DocumentPatchManager";
import {removeDeletedSubjectsFromSelection, updateSelectedSubjects} from "./helpers";
import {updateNetsLabel} from "./net/actions/updateNetsLabel";
import {
    addElements,
    addNodes,
    addRouteSegment,
    applyAutoLayoutIteration,
    removeNodeRules,
    removeNodes,
    setNodeLabel,
    toggleNodeSpecificRule,
    updateRouteSegments,
} from "./pcbLayoutNodes/actions";
import {commitMultiRoutingUpdate} from "./pcbLayoutNodes/actions/commitMultiRoutingUpdate";
import {setNodeRules} from "./pcbLayoutNodes/actions/setAndBakeNodeRules";
import {
    addGlobalRulesFromClipboard,
    duplicateGlobalPcbRuleSet,
    removeGlobalPcbRuleSet,
    setGlobalPcbRuleSet,
    toggleGlobalPcbRule,
} from "./pcbLayoutRuleSets/actions";

// For memoization by ref equality
const emptyArray: string[] = [];

// See https://immerjs.github.io/immer/patches
enablePatches();
// See https://immerjs.github.io/immer/freezing
if (isDevEnv()) {
    setAutoFreeze(true);
} else {
    setAutoFreeze(false);
}

const documentInitialState: IDocumentData | null = null;

const stateSanityCheck = (draftState: IDocumentData | null, action: any): draftState is IDocumentData => {
    if (!draftState) {
        FluxLogger.captureError(new DocumentReducerError(action.type, "documentState is null", action));
        return false;
    }

    return true;
};

// TODO: Move definition and rethink role of AnonymousUser
// Quick fix for bad action casts to any that pull an undefined current user off the object
// 946688400 Timestamp is 2000/01/01 1:00:00

const AnonymousUser: IUserData = {
    uid: ANON_ROLE_UID,
    handle: "ANON_ROLE_HANDLE",
    isAnonymous: true,
    full_name: "ANON_ROLE_NAME",
    last_active_at: 946688400,
    email: "",
    updated_at: 946688400,
    created_at: 946688400,
    sign_up_referrer: "",
    star_count: 3,
    picture: "",
    documents_count: 0,
    parts_count: 0,
    follower_count: 0,
    following_count: 0,
    is_test: false,
};

/**
 * Checks that the provided fields are defined on the action's payload and allows the
 * caller to provide additional checks in the fn parameter. If a field is undefined
 * or the additional checks fail, a capture error is logged.
 *
 * @param action the redux action
 * @param fields fields that must be defined on the action's payload
 * @param fn additional user provided checks
 * @returns true if fields are defined on the action and user checks pass, false otherwise
 */
function actionValid<P extends PayloadProps, U extends string & keyof P>(
    action: PayloadAction<P>,
    fields: Readonly<U[]>,
    fn?: (action: Readonly<PayloadAction<P>>) => boolean,
): action is PayloadAction<Required<P>> {
    return (
        fields.every((field) => {
            if (action.payload[field] == null) {
                FluxLogger.captureError(new MissingArgumentReducerError(action.type, field, action));
                return false;
            } else {
                return true;
            }
        }) && (fn ? fn(action) : true)
    );
}

/**
 * Checks that the draft state exists and is IDocumentData and checks that the provided
 * stateField are defined on the draft state. If a field is undefined a capture error is logged.
 *
 * @param draftState the reducer state to check
 * @param fields fields that must be defined on the state
 * @param action the action that triggered the reducer, used for logging
 * @returns true if state is valid and fields are defined on the state, false otherwise
 */
function stateValid(
    draftState: Readonly<IDocumentData | null>,
    fields: Readonly<(string & keyof IDocumentData)[]>,
    action: AnyAction,
): draftState is NonNullable<IDocumentData> {
    if (!stateSanityCheck(draftState, action)) {
        return false;
    }

    return fields.every((field) => {
        if (draftState[field] == null) {
            FluxLogger.captureError(new MissingFieldReducerError(action.type, field, action));
            return false;
        } else {
            return true;
        }
    });
}

/**
 * The main document reducer handling various operation/interaction in our app, especially in DiagramEditor and
 * PCBEditor. Please note that several actions have side effects, meaning they could trigger other jobs/tasks in
 * Flux AFTER this reducer finishes updating the state:
 *
 * 1. They could lead to generate action records and then sync both action records and the whole document with firebase
 *   - Check all redux actions with `shouldGenerateActionRecord` set to true
 *   - Check `makeGenerateActionRecordsEpic`, where the side effect is happening
 * 2. They could restart the simulator depending on changes to the simulator
 *    data dependencies: elements, routes...
 * 3. They could rebake PCB nodes depending on changes to the baked node data
 *    dependencies: pcbLayoutNodes, pcbLayoutRuleSets...
 *
 * NOTE: Here we are NOT using the builder pattern from redux-toolkit to construct the reducer, because we wanted to access the
 * [patch](https://immerjs.github.io/immer/patches/) feature from Immer.
 * However, redux-toolkit doesn't expose this API out (see [issue](https://github.com/reduxjs/redux-toolkit/issues/382)),
 * and that is why we need to construct the reducer via the `produce` function in order to get those patches
 */
const documentReducer = (
    state: IDocumentData | null = documentInitialState,
    action: IDocumentReduxAction,
): IDocumentData | null => {
    //
    // START OF setDocumentData BLOCK
    //
    // Initalize the document state when it is null.
    //
    if (!state) {
        if (isAnyOf(Actions.setDocumentData)(action)) {
            // QUESTION: if the state is not null, should we throw an error here?
            const {documentData} = action.payload;
            const result = {...documentData};

            return result;
        }
        return state;
    }
    //
    // START OF mergeDocumentData BLOCK
    //
    // Merge any data into the document without creating a patch. This is useful
    // for mocking or other special cases.
    //
    else if (isAnyOf(Actions.mergeDocumentData)(action)) {
        const {partialDocumentData} = action.payload;
        // QUESTION: merge deep with lodash? or just shallow merge?
        const result = {...state, ...partialDocumentData};

        return result;
    }
    //
    // START OF clearDocumentData BLOCK
    //
    else if (isAnyOf(Actions.clearDocumentData)(action)) {
        return null;
    } else {
        //
        // START OF all blocks that produce a `latestPatch`
        //
        return withHistoryUpdate(
            state,
            (draftState) => {
                //
                // START OF setSubjectProperties BLOCK
                //
                if (isAnyOf(Actions.setSubjectProperties)(action)) {
                    if (!actionValid(action, ["subjectType", "subjectUids", "setProperties"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {subjectType, subjectUids, setProperties} = action.payload;

                    for (const subjectUid of subjectUids) {
                        const subject =
                            subjectType === "elements"
                                ? draftState.elements?.[subjectUid]
                                : subjectType === "routes"
                                ? draftState.routes?.[subjectUid]
                                : subjectType === "nets"
                                ? draftState.nets?.[subjectUid]
                                : undefined;

                        if (!subject) {
                            FluxLogger.captureError(
                                new DocumentReducerError(
                                    action.type,
                                    `Can't set subject properties since the subject does not exist in ${subjectType} collection`,
                                    action,
                                ),
                            );
                            return draftState;
                        }

                        for (const setProperty of Object.values(setProperties)) {
                            if (subjectUids.length > 1) {
                                // If updating properties of multiple objects (eg for batch property
                                // edit), we need to update the existing property, not add a new
                                // property -- the provided property is likely a different subject's
                                // property, and adding it to this subject will cause it to have a
                                // duplicate entry for that property. For some default properties
                                // like part_type, this matters less, because the key "part_type" is
                                // used as the uid, and so only one entry can exist. But many
                                // properties (like "section") have unique uids, and end up with
                                // multiple entries during batch edit.
                                const updateExisting = getProperty(setProperty.name, subject.properties);

                                // Ensure property.state doesn't get saved to the document.
                                const updatedProperty = {
                                    ...omit(setProperty, "state"),
                                    // If property doesn't exist, add it (eg for when the user adds a
                                    // property via the property dialog)
                                    uid: updateExisting?.uid ?? guid(),
                                };
                                subject.properties[updatedProperty.uid] = updatedProperty;
                            } else {
                                if (!("uid" in setProperty)) {
                                    throw new Error("Single-subject properties must have a uid");
                                }
                                subject.properties[setProperty.uid] = setProperty;
                            }
                        }

                        const propertiesRequiringNodeRegenerate = [
                            defaultPropertyDefinitions.exclude_from_pcb!.label,
                            defaultPropertyDefinitions.pin_number!.label,
                        ];
                        const nodeRegenerateRequired = Object.values(setProperties).some((p) =>
                            propertiesRequiringNodeRegenerate.includes(p.name),
                        );

                        // setElementsAndGenerateNodes() etc is expensive, so don't execute it for most property
                        // changes -- only ones that affect the nodes, like pin_number and exclude_from_pcb.
                        if (nodeRegenerateRequired) {
                            const excludeFromPcb = ElementHelper.isExcludedFromPcb(subject.properties); // Important that subject.properties has been set above so we're using the new value if changed.
                            const excludeFromPcbWasChanged = Object.values(setProperties).some(
                                (p) => p.name === defaultPropertyDefinitions.exclude_from_pcb!.label,
                            );

                            if (excludeFromPcbWasChanged && excludeFromPcb) {
                                // If user has changed it so the node is excluded from PCB, remove it from PCB object tree.
                                removeNodesAndDescendants(draftState.pcbLayoutNodes, [subjectUid]);
                            }

                            if (!excludeFromPcb) {
                                if (subjectType === "elements") {
                                    setElementsAndGenerateNodes(draftState, [draftState.elements[subjectUid]!]);
                                }
                                // QUESTION: how to add only the nodes from the
                                // subjectUid? or do we need this in case the element is
                                // a layout or footprint?
                                const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                                pcbDomTreeManager.updateNetNodes(draftState);
                            }
                        }
                    }
                }
                //
                // START OF removeSubjectProperties BLOCK
                //
                else if (isAnyOf(Actions.removeSubjectProperties)(action)) {
                    if (!actionValid(action, ["subjectType", "subjectUids", "removePropertyKeys"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {subjectType, subjectUids, removePropertyKeys} = action.payload;

                    for (const subjectUid of subjectUids) {
                        const subjectProperties = draftState.elements[subjectUid]?.properties;
                        const wasExcludedFromPcb =
                            subjectProperties && ElementHelper.isExcludedFromPcb(subjectProperties);
                        removePropertyKeys.forEach((propertyKey) => {
                            if (subjectType === "elements" && draftState.elements) {
                                if (propertyKey in draftState.elements[subjectUid]!.properties) {
                                    delete draftState.elements[subjectUid]!.properties[propertyKey];
                                } else {
                                    // During batch-edit, properties are identified by their name,
                                    // not uid. Delete properties by name instead. Make sure we
                                    // delete *all* properties with the same name, because there
                                    // may be hidden duplicates, which our code doesn't handle
                                    // very well (and end up being even more confusing when they
                                    // don't delete properly!)
                                    const existingProperties = Object.values(
                                        draftState.elements[subjectUid]!.properties,
                                    ).filter((prop) => prop.name === propertyKey);
                                    for (const property of existingProperties) {
                                        delete draftState.elements[subjectUid]!.properties[property.uid];
                                    }
                                }
                            } else if (subjectType === "routes" && draftState.routes) {
                                delete draftState.routes[subjectUid]!.properties[propertyKey];
                            } else if (subjectType === "nets" && draftState.nets) {
                                delete draftState.nets[subjectUid]!.properties[propertyKey];
                            }
                        });

                        // TODO: This block could be a good candidate of domain function
                        // Start special case exclude_from_pcb element property
                        const isExcludedFromPcb = ElementHelper.isExcludedFromPcb(
                            draftState.elements[subjectUid]!.properties,
                        );
                        const isNet = subjectType === "nets" && draftState.nets;
                        if (wasExcludedFromPcb === true && isExcludedFromPcb === undefined) {
                            if (subjectType === "elements") {
                                if (!draftState.elements[subjectUid]) {
                                    FluxLogger.captureError(
                                        new DocumentReducerError(
                                            action.type,
                                            "Can't set excludeFromPcb to undefined (i.e. remove) since the element does not exist.",
                                            action,
                                        ),
                                    );
                                } else {
                                    setElementsAndGenerateNodes(draftState, [draftState.elements[subjectUid]!]);
                                }
                            }
                            // QUESTION: how to add only the nodes from the
                            // subjectUid? or do we need this in case the element is
                            // a layout or footprint?
                            const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                            pcbDomTreeManager.updateNetNodes(draftState);
                        } else if (isNet) {
                            const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                            pcbDomTreeManager.updateNetNodes(draftState);
                        }
                    }
                    // End special case exclude_from_pcb element property
                }
                //
                // START OF setAssets BLOCK
                //
                else if (isAnyOf(Actions.setAssets)(action)) {
                    if (!actionValid(action, ["setAssets"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["assets"], action)) {
                        return draftState;
                    }

                    const {setAssets} = action.payload;
                    const assets = draftState.assets;

                    Object.values(setAssets).forEach((assetData) => {
                        assets[assetData.uid] = assetData;

                        if (assetData.isThumbnail) {
                            draftState.preview_image = assetData.storageName || "";
                        }
                    });
                }
                //
                // START OF removeAssets BLOCK
                //
                else if (isAnyOf(Actions.removeAssets)(action)) {
                    if (!actionValid(action, ["removeAssetUids"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["assets"], action)) {
                        return draftState;
                    }

                    const {removeAssetUids} = action.payload;
                    const assets = draftState.assets;

                    removeAssetUids.forEach((assetUid) => {
                        if (draftState.preview_image === assets[assetUid]!.storageName) {
                            draftState.preview_image = "";
                        }

                        delete assets[assetUid];
                    });
                }
                //
                // START OF updateNetsLabel BLOCK
                //
                else if (isAnyOf(updateNetsLabel)(action)) {
                    if (!actionValid(action, ["netLabelMap"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {netLabelMap} = action.payload;
                    Object.entries(netLabelMap).forEach(([netId, label]) => {
                        draftState.nets[netId]!.label = label;
                        // Update the node label as well
                        draftState.pcbLayoutNodes[netId]!.name = label;
                    });
                }
                //
                // START OF setNodeLabel BLOCK
                //
                else if (isAnyOf(setNodeLabel)(action)) {
                    if (!actionValid(action, ["nodeUid", "label"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {nodeUid, label} = action.payload;

                    draftState.pcbLayoutNodes[nodeUid]!.name = label;
                }
                //
                // START OF setNodeLabelMulti BLOCK
                //
                else if (isAnyOf(Actions.setNodeLabelMulti)(action)) {
                    if (!actionValid(action, ["nodeUidLabelMap"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {nodeUidLabelMap} = action.payload;
                    Object.entries(nodeUidLabelMap).forEach(([nodeUid, label]) => {
                        draftState.pcbLayoutNodes[nodeUid]!.name = label;
                    });
                }
                //
                // START OF setNodeUserData BLOCK
                //
                else if (isAnyOf(Actions.setNodeUserData)(action)) {
                    if (!actionValid(action, ["nodeUid", "userData"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {nodeUid, userData} = action.payload;

                    draftState.pcbLayoutNodes[nodeUid]!.userCodeData = userData;
                }
                //
                // START OF setRulesetUserData BLOCK
                //
                else if (isAnyOf(Actions.setRulesetUserData)(action)) {
                    if (!actionValid(action, ["rulesetUid", "userData"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutRuleSets"], action)) {
                        return draftState;
                    }

                    const {rulesetUid, userData} = action.payload;

                    draftState.pcbLayoutRuleSets[rulesetUid]!.userCodeData = userData;
                }
                //
                // START OF setElementUserData BLOCK
                //
                else if (isAnyOf(Actions.setElementUserData)(action)) {
                    if (!actionValid(action, ["elementUid", "userData"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "elements"], action)) {
                        return draftState;
                    }

                    const {elementUid, userData} = action.payload;

                    draftState.elements[elementUid]!.userCodeData = userData;
                }
                //
                // START OF setAssetUserData BLOCK
                //
                else if (isAnyOf(Actions.setAssetUserData)(action)) {
                    if (!actionValid(action, ["assetUid", "userData"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "assets"], action)) {
                        return draftState;
                    }

                    const {assetUid, userData} = action.payload;

                    draftState.assets[assetUid]!.userCodeData = userData;
                }
                //
                // START OF setControlUserData BLOCK
                //
                else if (isAnyOf(Actions.setControlUserData)(action)) {
                    if (!actionValid(action, ["controlUid", "userData"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "controls"], action)) {
                        return draftState;
                    }

                    const {controlUid, userData} = action.payload;

                    if (!draftState.controls) {
                        draftState.controls = [];
                    }

                    const controlData = draftState.controls.find((control) => control.uid === controlUid);

                    if (controlData) {
                        controlData.userCodeData = userData;
                    }
                }
                //
                // START OF setNodeRulesMulti BLOCK
                //
                else if (isAnyOf(Actions.setNodeRulesMulti)(action)) {
                    if (!actionValid(action, ["nodeUidRulesMap"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {nodeUidRulesMap} = action.payload;
                    Object.entries(nodeUidRulesMap).forEach(([nodeUid, rules]) => {
                        draftState.pcbLayoutNodes[nodeUid]!.pcbNodeRuleSet = rules;
                    });
                }
                //
                // START OF setRuleSetSelector BLOCK
                //
                else if (isAnyOf(Actions.setRuleSetSelector)(action)) {
                    if (!actionValid(action, ["ruleSetUid", "selector"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutRuleSets"], action)) {
                        return draftState;
                    }

                    const {ruleSetUid, selector} = action.payload;

                    draftState.pcbLayoutRuleSets[ruleSetUid]!.selector = selector;
                }
                //
                // START OF setRuleSetRules BLOCK
                //
                else if (isAnyOf(Actions.setRuleSetRules)(action)) {
                    if (!actionValid(action, ["ruleSetUid", "rules"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutRuleSets"], action)) {
                        return draftState;
                    }

                    const {ruleSetUid, rules} = action.payload;

                    draftState.pcbLayoutRuleSets[ruleSetUid]!.rules = rules;
                }
                //
                // START OF addNodes BLOCK
                //
                else if (isAnyOf(addNodes)(action)) {
                    if (!actionValid(action, ["nodes"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutNodes", "elements", "routes"], action)) {
                        return draftState;
                    }

                    const {nodes} = action.payload;

                    if (
                        hasTopLevelContainerNode(Object.values(nodes)) &&
                        !hasTopLevelContainerNode(Object.values(state.pcbLayoutNodes)) &&
                        state.elements &&
                        Object.values(state.elements).length > 0
                    ) {
                        // If we are re-adding top-level layout/footprint node when
                        // - we already have parts on the board
                        // - AND there's no existing top-level layout/footprint node
                        // Then need to also add all sub-layouts for the existed parts
                        // NOTE: This will then trigger `bakeNodesAndAddFromElements` in `bakeNodesFromLatestPatch.ts`
                        setElementsAndGenerateNodes(draftState, Object.values(state.elements));
                    } else {
                        addPcbNodes(draftState.pcbLayoutNodes, Object.values(nodes));
                    }
                }
                //
                // START OF removeNodes BLOCK
                //
                else if (isAnyOf(removeNodes)(action)) {
                    // NOTE: Here we are passing toggleBakedNodes as baked nodes and not as UIDs
                    // This is because the toggling logic needs to get some info from them, like their name
                    // and if they are currently enabled. Can this cause problem in the future?

                    if (!actionValid(action, ["removeNodeUids", "toggleBakedNodes"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {removeNodeUids, toggleBakedNodes} = action.payload;
                    const {pcbLayoutNodes} = draftState;

                    removeNodesAndDescendants(pcbLayoutNodes, removeNodeUids);
                    removeDeletedSubjectsFromSelection(draftState, removeNodeUids);

                    if (toggleBakedNodes.length > 0) {
                        toggleNodesWithImportant(draftState, toggleBakedNodes);
                    }
                }
                //
                // START OF updateDescription BLOCK
                //
                else if (isAnyOf(Actions.updateDescription)(action)) {
                    if (!actionValid(action, ["description"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {description} = action.payload;
                    draftState.description = description;
                }
                //
                // START OF setBelongsToPartUid BLOCK
                //
                else if (isAnyOf(Actions.setBelongsToPartUid)(action)) {
                    if (!actionValid(action, ["partUid"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {partUid} = action.payload;
                    draftState.belongs_to_part_uid = partUid;
                }
                //
                // START OF updateSimulationTimeStep BLOCK
                //
                else if (isAnyOf(Actions.updateSimulationTimeStep)(action)) {
                    if (!actionValid(action, ["value"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["simulation"], action)) {
                        return draftState;
                    }

                    const {value} = action.payload;
                    draftState.simulation.time_step_size = value;
                }
                //
                // START OF updateSimulationTimeStepUnit BLOCK
                //
                else if (isAnyOf(Actions.updateSimulationTimeStepUnit)(action)) {
                    if (!actionValid(action, ["unit"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["simulation"], action)) {
                        return draftState;
                    }

                    const {unit} = action.payload;
                    draftState.simulation.time_step_size_unit = unit;
                }
                //
                // START OF applyActionRecords BLOCK
                //
                else if (isAnyOf(Actions.applyActionRecords)(action)) {
                    if (
                        !actionValid(action, ["actionRecords"], (action) => action.payload.actionRecords.length !== 0)
                    ) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {actionRecords} = action.payload;

                    // NOTE: It is important to NOT return anything here, because that will
                    // end up replacing the whole state.
                    // See detail: https://immerjs.github.io/immer/return/
                    // `applyActionRecordsToDoc` mutates the state in place already
                    applyActionRecordsToDoc(draftState, actionRecords);
                }
                //
                // START OF applyAutoLayoutIteration BLOCK
                //
                else if (isAnyOf(applyAutoLayoutIteration)(action)) {
                    if (!actionValid(action, ["allNodes", "iteration"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {allNodes, iteration} = action.payload;
                    const newNodes = Object.keys(iteration.nodes).reduce((acc, nodeUid) => {
                        const node = allNodes[nodeUid];
                        if (node && AutoLayoutHelpers.isAutoLayoutNode(node)) {
                            acc.push(createNodeFromVirtualNode(node));
                        }
                        return acc;
                    }, [] as AnyPcbNode[]);

                    addPcbNodes(draftState.pcbLayoutNodes, newNodes);
                }
                //
                // START OF setDocumentProperties BLOCK
                //
                else if (isAnyOf(Actions.setDocumentProperties)(action)) {
                    if (!actionValid(action, ["setProperties"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["properties"], action)) {
                        return draftState;
                    }

                    const {setProperties} = action.payload;
                    const properties = draftState.properties;

                    Object.values(setProperties).forEach((setProperty) => {
                        properties[setProperty.uid] = setProperty;
                    });
                }
                //
                // START OF removeDocumentProperties BLOCK
                //
                else if (isAnyOf(Actions.removeDocumentProperties)(action)) {
                    if (!actionValid(action, ["propertyUids"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["properties"], action)) {
                        return draftState;
                    }

                    const {propertyUids} = action.payload;
                    const properties = draftState.properties;

                    propertyUids.forEach((propertyUid) => {
                        delete properties[propertyUid];
                    });
                }
                //
                // START OF setGlobalPcbRuleSet BLOCK
                //
                else if (isAnyOf(setGlobalPcbRuleSet)(action)) {
                    if (!actionValid(action, ["setPcbLayoutRules"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutRuleSets"], action)) {
                        return draftState;
                    }

                    const {setPcbLayoutRules, selectedGlobalPcbLayoutRule} = action.payload;

                    for (const key in setPcbLayoutRules) {
                        const pcbLayoutRuleSet = setPcbLayoutRules[key]!;
                        draftState.pcbLayoutRuleSets[pcbLayoutRuleSet.uid] = pcbLayoutRuleSet;
                    }

                    if (selectedGlobalPcbLayoutRule) {
                        draftState.selectedObjectUids = [selectedGlobalPcbLayoutRule];
                    }
                }
                //
                // START OF setNodeRules BLOCK
                //
                else if (isAnyOf(setNodeRules)(action)) {
                    if (!actionValid(action, ["updatedNodes"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {updatedNodes} = action.payload;

                    Object.values(updatedNodes).forEach((updatedNode) => {
                        const node = draftState.pcbLayoutNodes[updatedNode.nodeUid];
                        if (node) {
                            if (!node.pcbNodeRuleSet) {
                                node.pcbNodeRuleSet = updatedNode.updatedPcbLayoutRules;
                            } else {
                                Object.values(updatedNode.updatedPcbLayoutRules).forEach((rule) => {
                                    const ruleWithOrder = DocumentStorageHelper.setPcbLayoutRuleOrder(
                                        rule,
                                        Object.keys(node.pcbNodeRuleSet).length,
                                    );
                                    node.pcbNodeRuleSet[rule.key] = ruleWithOrder;
                                });
                            }
                        }
                    });
                }
                //
                // START OF removeNodeLayoutRules BLOCK
                //
                else if (isAnyOf(removeNodeRules)(action)) {
                    if (!actionValid(action, ["ruleKeys", "nodeId"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {ruleKeys, nodeId} = action.payload;
                    const node = draftState.pcbLayoutNodes[nodeId];

                    if (node) {
                        if (node.pcbNodeRuleSet) {
                            ruleKeys.forEach((ruleKey) => {
                                delete node.pcbNodeRuleSet[ruleKey];
                            });
                        }
                    }
                }
                //
                // START OF toggleGlobalPcbRule BLOCK
                //
                else if (isAnyOf(toggleGlobalPcbRule)(action)) {
                    // NOTE: Had to remove subRuleId to allow 'if (!subRuleId) {' case below
                    if (!actionValid(action, ["ruleId" /* subRuleId */])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutRuleSets"], action)) {
                        return draftState;
                    }

                    const {ruleId, subRuleId, state} = action.payload;
                    const rule = ruleId && draftState.pcbLayoutRuleSets[ruleId];

                    if (rule) {
                        if (!subRuleId) {
                            // toggle the whole rule
                            rule.disabled = state;
                            Object.values(rule.rules).forEach((subRule) => (subRule.disabled = rule.disabled));
                        } else {
                            // toggle just the sub-rule
                            const subRule = Object.values(rule.rules).find((rule) => rule.uid === subRuleId);
                            if (subRule) {
                                subRule.disabled = state;
                                rule.disabled = Object.values(rule.rules).every((subRule) => subRule.disabled);
                            }
                        }
                    }

                    return draftState;
                }
                //
                // START OF toggleNodeSpecificRule BLOCK
                //
                else if (isAnyOf(toggleNodeSpecificRule)(action)) {
                    if (!actionValid(action, ["ruleId", "nodeId"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutRuleSets"], action)) {
                        return draftState;
                    }

                    const {ruleId, nodeId, toggleState} = action.payload;
                    if (nodeId) {
                        const node = draftState.pcbLayoutNodes[nodeId];
                        if (node) {
                            const rule = Object.values(node.pcbNodeRuleSet).find((rule) => rule.uid === ruleId);
                            if (rule) {
                                rule.disabled = toggleState;
                            }
                        }
                    }

                    return draftState;
                }
                //
                // START OF duplicatePcbLayoutRules BLOCK
                //
                else if (isAnyOf(duplicateGlobalPcbRuleSet)(action)) {
                    if (!actionValid(action, ["ruleUids"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutRuleSets"], action)) {
                        return draftState;
                    }

                    const {ruleUids} = action.payload;

                    ruleUids.forEach((ruleUid) => {
                        const existing = draftState.pcbLayoutRuleSets[ruleUid];
                        if (existing) {
                            const newRule = deepCopy(existing);
                            newRule.uid = guid();
                            if ("name" in newRule) {
                                let index = 2;
                                let newName = `${newRule.name} ${index}`;
                                while (
                                    // eslint-disable-next-line no-loop-func
                                    Object.values(draftState.pcbLayoutRuleSets).some(
                                        (ruleset) => (ruleset as any).name === newName,
                                    )
                                ) {
                                    index++;
                                    newName = `${newRule.name} ${index}`;
                                }
                                newRule.name = newName;
                            }
                            // generate new id's for the sub-rules
                            Object.values(newRule.rules).forEach((rule: any) => {
                                rule.uid = guid();
                            });
                            draftState.pcbLayoutRuleSets[newRule.uid] = newRule;
                        }
                    });

                    return draftState;
                }
                //
                // START OF removeGlobalPcbRuleSet BLOCK
                //
                else if (isAnyOf(removeGlobalPcbRuleSet)(action)) {
                    if (!actionValid(action, ["pcbLayoutRuleUids"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutRuleSets"], action)) {
                        return draftState;
                    }

                    const {pcbLayoutRuleUids} = action.payload;
                    const pcbLayoutRuleSets = draftState.pcbLayoutRuleSets;

                    pcbLayoutRuleUids.forEach((pcbLayoutRuleUid) => {
                        delete pcbLayoutRuleSets[pcbLayoutRuleUid];
                    });
                }
                //
                // START OF setControls BLOCK
                //
                else if (isAnyOf(Actions.setControls)(action)) {
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {controls} = action.payload;
                    draftState.controls = controls;
                }
                //
                // START OF addElements BLOCK
                //
                else if (isAnyOf(addElements)(action)) {
                    if (!actionValid(action, ["elements"], (action) => action.payload.elements.length !== 0)) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements", "uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {elements, selectElements, inPcbEditor, dropPosition} = action.payload;

                    // NOTE: Historically, part documents were created that included
                    // themselves for a kind of "preview" of the published part. For
                    // snapshots, what we want generally is a DAG, but that is not enforced
                    // here, and it is likely very rare to have cycles beyond
                    // self-inclusion. For more discussion, see
                    // https://buildwithflux.workplace.com/groups/291253750161697/posts/340449055242166/
                    // See yieldAllSubDocuments.
                    if (
                        elements.some((element) => {
                            const {document_import_uid, part_uid} = element.part_version_data_cache;
                            return (
                                document_import_uid === draftState.uid ||
                                (part_uid && part_uid === draftState.belongs_to_part_uid)
                            );
                        })
                    ) {
                        FluxLogger.captureError(
                            new DocumentReducerError(
                                action.type,
                                "Cannot add a part to the document that it was published from",
                                action,
                            ),
                        );
                        return draftState;
                    }

                    setElementsAndGenerateNodes(draftState, elements, {inPcbEditor, dropPosition});

                    const symbolUids = elements.flatMap((e) =>
                        ElementHelper.getSymbolInfo(e).symbols.map((s) => ElementHelper.makeSymbolUid(e.uid, s.name)),
                    );

                    if (selectElements) {
                        updateSelectedSubjects(draftState, [...elements.map((element) => element.uid), ...symbolUids]);
                    }
                }
                //
                // START OF addSubjectsFromClipboard BLOCK
                //
                else if (isAnyOf(Actions.addSubjectsFromClipboard)(action)) {
                    if (
                        !actionValid(
                            action,
                            ["elements", "routeVertices"],
                            (action) => action.payload.elements.length !== 0,
                        )
                    ) {
                        return draftState;
                    }
                    if (
                        !stateValid(
                            draftState,
                            ["elements", "routes", "routeVertices", "uid", "pcbLayoutNodes"],
                            action,
                        )
                    ) {
                        return draftState;
                    }

                    const {elements, routeVertices} = action.payload;
                    const draftElements = draftState.elements;
                    const draftRouteVertices = draftState.routeVertices;

                    elements.forEach((element) => {
                        draftElements[element.uid] = element;
                    });

                    Object.entries(routeVertices).forEach(([uid, routeVertex]) => {
                        draftRouteVertices[uid] = routeVertex;
                    });

                    setElementsAndGenerateNodes(draftState, Object.values(elements));
                    const pcbTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);

                    pcbTreeManager.updateNetNodes(draftState);

                    draftState.pcbLayoutNodes = pcbTreeManager.treeNodes as PcbNodesMap<AnyPcbNode>;
                }
                //
                // START OF addSubjectsPropertiesFromClipboard BLOCK
                //
                else if (isAnyOf(Actions.addSubjectsPropertiesFromClipboard)(action)) {
                    if (
                        !actionValid(
                            action,
                            ["targetElementUids", "targetRouteUids", "properties"],
                            (action) => Object.keys(action.payload.properties).length !== 0,
                        )
                    ) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements", "routes"], action)) {
                        return draftState;
                    }

                    const {targetElementUids, targetRouteUids, properties} = action.payload;

                    targetElementUids.forEach((elementUid) => {
                        Object.entries(properties).forEach(([propertyUid, property]) => {
                            if (draftState.elements[elementUid]!) {
                                const existingPropertyWithSameName = Object.values(
                                    draftState.elements[elementUid]!.properties,
                                ).find((existingProperty) => existingProperty.name === property.name);

                                if (existingPropertyWithSameName) {
                                    // Here we when overriding the existing property, make sure to also override the `uid`.
                                    // Otherwise the key and the uid of the copied property no longer matches
                                    draftState.elements[elementUid]!.properties[existingPropertyWithSameName.uid] = {
                                        ...property,
                                        uid: existingPropertyWithSameName.uid,
                                    };
                                } else {
                                    draftState.elements[elementUid]!.properties[propertyUid] = property;
                                }
                            }
                        });
                    });
                    targetRouteUids.forEach((routeUid) => {
                        Object.entries(properties).forEach(([propertyUid, property]) => {
                            if (draftState.routes[routeUid]!) {
                                const existingPropertyWithSameName = Object.values(
                                    draftState.routes[routeUid]!.properties,
                                ).find((existingProperty) => existingProperty.name === property.name);

                                if (existingPropertyWithSameName) {
                                    draftState.routes[routeUid]!.properties[existingPropertyWithSameName.uid] =
                                        property;
                                } else {
                                    draftState.routes[routeUid]!.properties[propertyUid] = property;
                                }
                            }
                        });
                    });

                    if (targetElementUids.length === 0 && targetRouteUids.length === 0) {
                        Object.entries(properties).forEach(([propertyUid, property]) => {
                            const existingPropertyWithSameName = Object.values(draftState!.properties).find(
                                (existingProperty) => existingProperty.name === property.name,
                            );

                            if (existingPropertyWithSameName) {
                                // Here we when overriding the existing property, make sure to also override the `uid`.
                                // Otherwise the key and the uid of the copied property no longer matches
                                draftState!.properties[existingPropertyWithSameName.uid] = {
                                    ...property,
                                    uid: existingPropertyWithSameName.uid,
                                };
                            } else {
                                draftState!.properties[propertyUid] = property;
                            }
                        });
                    }
                }
                //
                // START OF addGlobalRulesFromClipboard BLOCK
                //
                else if (isAnyOf(addGlobalRulesFromClipboard)(action)) {
                    if (
                        !actionValid(
                            action,
                            ["targetObjectUids", "nodeLayoutRules"],
                            (action) => Object.keys(action.payload.nodeLayoutRules).length !== 0,
                        )
                    ) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {targetObjectUids, nodeLayoutRules} = action.payload;

                    targetObjectUids.forEach((objectUid) => {
                        Object.entries(nodeLayoutRules).forEach(([layoutRuleUid, layoutRule]) => {
                            if (draftState.pcbLayoutNodes[objectUid]!) {
                                draftState.pcbLayoutNodes[objectUid]!.pcbNodeRuleSet[layoutRuleUid as RuleKey] =
                                    layoutRule;
                            } else if (draftState.pcbLayoutRuleSets[objectUid]!) {
                                draftState.pcbLayoutRuleSets[objectUid]!.rules[layoutRuleUid as RuleKey] = layoutRule;
                            }
                        });
                    });
                }
                //
                // START OF addRouteSegment BLOCK
                // NOTE: see also commitMultiRoutingUpdate
                //
                else if (isAnyOf(addRouteSegment)(action)) {
                    if (!actionValid(action, ["addedNodes"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {deletedNodes, addedNodes, updatedNodePositions} = action.payload;

                    addPcbNodes(draftState.pcbLayoutNodes, addedNodes);

                    removeNodesAndDescendants(
                        draftState.pcbLayoutNodes,
                        deletedNodes.map((node) => node.uid),
                    );

                    // NOTE: for disabling autopositioning
                    Object.entries(updatedNodePositions).forEach(([uid, position]) => {
                        const elementNode = draftState.pcbLayoutNodes[uid];
                        if (elementNode) {
                            elementNode.pcbNodeRuleSet.position = position;
                        }
                    });
                }
                //
                // START OF updateRouteSegments BLOCK
                //
                else if (isAnyOf(updateRouteSegments)(action)) {
                    if (!actionValid(action, ["updates"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    addPcbNodes(draftState.pcbLayoutNodes, Object.values(action.payload.updates));
                }
                //
                // START OF commitMultiRoutingUpdate BLOCK
                //
                else if (isAnyOf(commitMultiRoutingUpdate)(action)) {
                    if (!actionValid(action, ["addedNodes"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {deletedNodes, addedNodes, updatedNodes} = action.payload;

                    addPcbNodes(draftState.pcbLayoutNodes, addedNodes);

                    removeNodesAndDescendants(draftState.pcbLayoutNodes, deletedNodes);

                    Object.values(updatedNodes).forEach((updatedNode) => {
                        const node = draftState.pcbLayoutNodes[updatedNode.nodeUid];
                        if (node) {
                            if (!node.pcbNodeRuleSet) {
                                node.pcbNodeRuleSet = updatedNode.updatedPcbLayoutRules;
                            } else {
                                Object.values(updatedNode.updatedPcbLayoutRules).forEach((rule) => {
                                    const ruleWithOrder = DocumentStorageHelper.setPcbLayoutRuleOrder(
                                        rule,
                                        Object.keys(node.pcbNodeRuleSet).length,
                                    );
                                    node.pcbNodeRuleSet[rule.key] = ruleWithOrder;
                                });
                            }
                        }
                    });
                }
                //
                // START OF deleteSubjects BLOCK
                //
                else if (isAnyOf(Actions.deleteSubjects)(action)) {
                    if (
                        !actionValid(
                            action,
                            ["subjectUids"],
                            (action) =>
                                action.payload.vertexUpdates?.length !== 0 ||
                                action.payload.routeUpdates?.length !== 0 ||
                                action.payload.subjectUids?.length !== 0,
                        )
                    ) {
                        return draftState;
                    }
                    if (
                        !stateValid(
                            draftState,
                            ["elements", "routes", "pcbLayoutRuleSets", "routeVertices", "uid", "pcbLayoutNodes"],
                            action,
                        )
                    ) {
                        return draftState;
                    }

                    const {vertexUpdates, subjectUids, alsoDeselectUids} = action.payload;
                    const {
                        elements: oldElements,
                        routes: oldRoutes,
                        pcbLayoutRuleSets: oldPcbLayoutRuleSets,
                        routeVertices: oldRouteVertices,
                    } = draftState;
                    const deletedElementUids: string[] = emptyArray;

                    /**
                     * Usually an element has a corresponding pcb node with the same uid, so we want
                     * to remove that corresponding node sub-tree. However, there is an exception:
                     * - GND terminal has a different uid because we generate a net node for it
                     *
                     * Excluding the exception so that we are not trying to remove an non-existed node
                     */
                    removeNodesAndDescendants(
                        draftState.pcbLayoutNodes,
                        subjectUids.filter(
                            (uid) =>
                                !!draftState.elements[uid] && !ElementHelper.isGroundElement(draftState.elements[uid]!),
                        ),
                    );

                    subjectUids
                        .filter((uid) => !!oldElements[uid])
                        .forEach((elementUid) => {
                            delete oldElements[elementUid];
                            deletedElementUids.push(elementUid);

                            const nodeSelector = PcbLayoutRuleCompiler.createUidAttributeSelector(
                                PcbNodeTypes.element,
                                elementUid,
                            );
                            const ruleSet = PcbLayoutRuleCompiler.getRuleSetBasedOnSelector(
                                nodeSelector,
                                oldPcbLayoutRuleSets,
                            );

                            if (ruleSet) {
                                delete oldPcbLayoutRuleSets[ruleSet?.uid];
                            }
                        });

                    removeDeletedSubjectsFromSelection(draftState, [...subjectUids, ...alsoDeselectUids]);

                    const routesVerticesAndElements = {
                        vertices: oldRouteVertices,
                        routes: oldRoutes,
                        elements: oldElements,
                    };
                    DocumentStorageHelper.applyVertexUpdates(routesVerticesAndElements, vertexUpdates || []);

                    draftState.elements = routesVerticesAndElements.elements;
                    draftState.routes = routesVerticesAndElements.routes;
                    draftState.routeVertices = routesVerticesAndElements.vertices;

                    const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                    if (vertexUpdates && vertexUpdates.length > 0) {
                        pcbDomTreeManager.updateNetNodes(draftState, true);
                    }
                }
                //
                // START OF selectObjects BLOCK
                //
                else if (isAnyOf(Actions.selectObjects)(action)) {
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {selectedObjectUids} = action.payload;
                    updateSelectedSubjects(draftState, selectedObjectUids);
                }
                //
                // START OF lockObjects BLOCK
                //
                else if (isAnyOf(Actions.lockObjects)(action)) {
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {objectUids, mode, locked} = action.payload;
                    objectUids.forEach((uid) => {
                        if (mode === EditorModes.schematic && draftState.elements[uid]) {
                            draftState.elements[uid]!.locked = locked;
                        }

                        if (mode === EditorModes.pcb && draftState.pcbLayoutNodes[uid]) {
                            // Lock inheritance is handled in the PcbLayoutEngine for performance reasons
                            draftState.pcbLayoutNodes[uid]!.locked = locked;
                        }
                    });
                }
                // START OF updateElementsSchematicPosition BLOCK
                //
                else if (isAnyOf(Actions.updateElementsSchematicPosition)(action)) {
                    if (
                        !actionValid(
                            action,
                            ["elements"],
                            (action) =>
                                action.payload.elements.length !== 0 || action.payload.vertexUpdates?.length !== 0,
                        )
                    ) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements"], action)) {
                        return draftState;
                    }

                    const {elements, vertexUpdates} = action.payload;

                    const routesVerticesAndElements = {
                        vertices: draftState.routeVertices || {},
                        elements: draftState.elements,
                        routes: draftState.routes,
                    };

                    if (vertexUpdates) {
                        // This block should mirror the equivalent block in the updateWiring action.
                        // It is important to ensure any vertex updates (eg from dragging selected
                        // terminals over wires or other terminals) trigger the appropriate net
                        // updates.
                        DocumentStorageHelper.applyVertexUpdates(routesVerticesAndElements, vertexUpdates || []);
                        ensureRouteVertexState(draftState);
                        const pcbTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                        pcbTreeManager.updateNetNodes({
                            ...draftState,
                            routeVertices: routesVerticesAndElements.vertices,
                        });
                        draftState.pcbLayoutNodes = pcbTreeManager.treeNodes as PcbNodesMap<AnyPcbNode>;
                    }

                    elements.forEach((updatedElement) => {
                        draftState.elements[updatedElement.uid]!.diagram_position = Object.values(
                            updatedElement.symbol_placements ?? {},
                        )[0];
                        draftState.elements[updatedElement.uid]!.symbol_placements = updatedElement.symbol_placements;
                        draftState.elements[updatedElement.uid]!.updated_at = new Date().getTime();
                    });
                }

                //
                // START OF updateElementsLabel BLOCK
                //
                else if (isAnyOf(Actions.updateElementsLabel)(action)) {
                    if (!actionValid(action, ["elements"], (action) => action.payload.elements.length !== 0)) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements"], action)) {
                        return draftState;
                    }

                    const {elements} = action.payload;
                    const oldElements = draftState.elements;
                    const oldPcbLayoutNodes = draftState.pcbLayoutNodes;

                    elements.forEach((updatedElement) => {
                        oldElements[updatedElement.uid]!.label = updatedElement.label;
                        oldElements[updatedElement.uid]!.updated_at = new Date().getTime();

                        if (oldPcbLayoutNodes && oldPcbLayoutNodes[updatedElement.uid]!) {
                            oldPcbLayoutNodes[updatedElement.uid]!.name = updatedElement.label || "";
                        }
                    });

                    const hasTerminals = elements.some((el) => el.part_uid === GENESIS_TERMINAL_PART_UID);
                    const hasNetPortal = elements.some((el) => ElementHelper.isPortal(el));

                    // We update the pcb layout nodes only if we have terminals or net portal so that we cover the case
                    // where a portal has been renamed by the user.
                    if (hasTerminals || hasNetPortal) {
                        setElementsAndGenerateNodes(draftState, Object.values(elements));

                        const pcbTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                        pcbTreeManager.updateNetNodes(draftState);

                        draftState.pcbLayoutNodes = pcbTreeManager.treeNodes as PcbNodesMap<AnyPcbNode>;
                    }
                }
                //
                // START OF updateElementProperties BLOCK
                //
                else if (isAnyOf(Actions.updateElementProperties)(action)) {
                    if (!actionValid(action, ["elementUid", "properties"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements"], action)) {
                        return draftState;
                    }

                    const {elementUid, properties} = action.payload;

                    draftState.elements[elementUid]!.properties = properties;
                }
                //
                // START OF updateRouteProperties BLOCK
                //
                else if (isAnyOf(Actions.updateRouteProperties)(action)) {
                    if (!actionValid(action, ["routeUid", "properties"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["routes"], action)) {
                        return draftState;
                    }

                    const {routeUid, properties} = action.payload;

                    draftState.routes[routeUid]!.properties = properties;
                }
                //
                // START OF updateRoutesLabel BLOCK
                //
                else if (isAnyOf(Actions.updateRoutesLabel)(action)) {
                    if (!actionValid(action, ["routes"], (action) => action.payload.routes.length !== 0)) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["routes"], action)) {
                        return draftState;
                    }

                    const {routes} = action.payload;
                    const oldRoutes = draftState.routes;

                    routes.forEach((updatedRoute) => {
                        oldRoutes[updatedRoute.uid]!.label = updatedRoute.label;
                        oldRoutes[updatedRoute.uid]!.updated_at = new Date().getTime();
                    });
                }
                //
                // START OF updateWiring BLOCK
                //
                else if (isAnyOf(Actions.updateWiring)(action)) {
                    if (!actionValid(action, [])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements", "routes", "uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {vertexUpdates, selectedObjectUids} = action.payload;
                    const {routeVertices: oldRouteVertices, elements: oldElements, routes: oldRoutes} = draftState;

                    const routesVerticesAndElements = {
                        vertices: oldRouteVertices || {},
                        elements: oldElements,
                        routes: oldRoutes,
                    };

                    // This block should mirror the equivalent block in the updateElementsSchematicPositionThunk
                    // action, particularly noting the net updates below. See comment there.
                    DocumentStorageHelper.applyVertexUpdates(routesVerticesAndElements, vertexUpdates || []);

                    if (
                        !routesVerticesAndElements.vertices ||
                        !Object.keys(routesVerticesAndElements.vertices).length
                    ) {
                        routesVerticesAndElements.routes = {};
                    }

                    draftState.elements = routesVerticesAndElements.elements;
                    draftState.routeVertices = routesVerticesAndElements.vertices;
                    draftState.routes = routesVerticesAndElements.routes;

                    ensureRouteVertexState(draftState);

                    const pcbTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                    const {selectionUpdates} = pcbTreeManager.updateNetNodes({
                        ...draftState,
                        routeVertices: routesVerticesAndElements.vertices,
                    });

                    if (selectedObjectUids) {
                        // Using a set to dedupe the selection updates
                        const selections = new Set<string>(selectedObjectUids.concat(selectionUpdates));
                        updateSelectedSubjects(draftState, Array.from(selections.values()));
                    }

                    draftState.pcbLayoutNodes = pcbTreeManager.treeNodes as PcbNodesMap<AnyPcbNode>;
                }
                //
                // START OF flipElements BLOCK
                //
                else if (isAnyOf(Actions.flipElements)(action)) {
                    if (
                        !actionValid(
                            action,
                            ["elements", "routeVertexUpdates"],
                            (action) => action.payload.elements.length !== 0,
                        )
                    ) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements", "routeVertices"], action)) {
                        return draftState;
                    }

                    const {elements, routeVertexUpdates} = action.payload;
                    const {elements: oldElements} = draftState;

                    (routeVertexUpdates || []).forEach((updatedVertices) => {
                        applyChange(draftState.routeVertices, true, updatedVertices);
                    });

                    elements.forEach((updatedElement) => {
                        oldElements[updatedElement.uid]!.diagram_position = Object.values(
                            updatedElement.symbol_placements ?? {},
                        )[0];
                        oldElements[updatedElement.uid]!.symbol_placements = updatedElement.symbol_placements;
                        oldElements[updatedElement.uid]!.updated_at = new Date().getTime();
                    });
                }
                //
                // START OF convertSubjectsToNewPart BLOCK
                //
                else if (isAnyOf(Actions.convertSubjectsToNewPart)(action)) {
                    if (
                        !actionValid(
                            action,
                            ["elements", "partVersion", "newElement"],
                            (action) => action.payload.elements.length !== 0,
                        )
                    ) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements", "routeVertices", "routes"], action)) {
                        return draftState;
                    }

                    const {elements: originalElements, newElement, vertexUpdates} = action.payload;
                    const {routeVertices: oldRouteVertices, routes: oldRoutes, elements: oldElements} = draftState;

                    // delete the old elements, add the new
                    DocumentStorageHelper.deleteElements(draftState as IDocumentData, originalElements);
                    // delete old pcb layout nodes
                    const oldElementNodes = originalElements.map((el) => el.uid);
                    removeNodesAndDescendants(draftState.pcbLayoutNodes, oldElementNodes);

                    setElementsAndGenerateNodes(draftState, [newElement]);

                    // apply route updates and vertex updates
                    const newRoutesAndElements = {
                        vertices: oldRouteVertices,
                        routes: oldRoutes,
                        elements: oldElements,
                    };
                    DocumentStorageHelper.applyVertexUpdates(newRoutesAndElements, vertexUpdates || []);

                    draftState.elements = newRoutesAndElements.elements;
                    draftState.routes = newRoutesAndElements.routes;
                    draftState.routeVertices = newRoutesAndElements.vertices;
                }
                //
                // START OF createLocalPart BLOCK
                //
                else if (isAnyOf(Actions.createLocalPart)(action)) {
                    // The `createLocalPart` action is only for dev use!
                    if (!actionValid(action, ["element", "partVersion", "newElement"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements"], action)) {
                        return draftState;
                    }

                    const {element: originalElement, newElement} = action.payload;

                    // delete the old element first
                    DocumentStorageHelper.deleteElements(draftState, [originalElement]);
                    // delete old pcb layout nodes
                    removeNodesAndDescendants(draftState.pcbLayoutNodes, [originalElement.uid]);
                    // then add the new element
                    setElementsAndGenerateNodes(draftState, [newElement]);
                }
                //
                // START OF createCommentThread BLOCK
                //
                else if (isAnyOf(Actions.createCommentThread)(action)) {
                    if (!actionValid(action, ["commentThread"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["comment_threads"], action)) {
                        return draftState;
                    }

                    const {commentThread} = action.payload;
                    draftState.comment_threads[commentThread.uid] = commentThread;
                }
                //
                // START OF incrementCommentThreadCommentCount BLOCK
                //
                else if (isAnyOf(Actions.incrementCommentThreadCommentCount)(action)) {
                    if (!actionValid(action, ["commentThreadUid"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["comment_threads"], action)) {
                        return draftState;
                    }
                    const thread = draftState.comment_threads[action.payload.commentThreadUid];
                    if (thread) {
                        thread.comment_count = thread.comment_count ? thread.comment_count + 1 : 1;
                        // The record in firestore has it's updated_at set on persistence separate from this
                        // TODO: Refactor how comment thread sync between firestore and redux is handled.
                        thread.updated_at = Date.now();
                    }
                }
                //
                // START OF pinCommentThread BLOCK
                //
                else if (isAnyOf(Actions.pinCommentThread)(action)) {
                    if (!actionValid(action, ["commentThreadUid", "pinned"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["comment_threads"], action)) {
                        return draftState;
                    }

                    const {commentThreadUid, pinned} = action.payload;

                    draftState.comment_threads[commentThreadUid]!.pinned = pinned;
                    draftState.comment_threads[commentThreadUid]!.updated_at = new Date().getTime();
                }
                //
                // START OF resolveCommentThread BLOCK
                //
                else if (isAnyOf(Actions.resolveCommentThread)(action)) {
                    if (!actionValid(action, ["commentThreadUid"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["comment_threads"], action)) {
                        return draftState;
                    }

                    const {commentThreadUid} = action.payload;

                    draftState.comment_threads[commentThreadUid]!.resolved = true;
                    draftState.comment_threads[commentThreadUid]!.updated_at = new Date().getTime();
                }
                //
                // START OF unresolveCommentThread BLOCK
                //
                else if (isAnyOf(Actions.unresolveCommentThread)(action)) {
                    if (!actionValid(action, ["commentThreadUid"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["comment_threads"], action)) {
                        return draftState;
                    }

                    const {commentThreadUid} = action.payload;

                    draftState.comment_threads[commentThreadUid]!.resolved = false;
                    draftState.comment_threads[commentThreadUid]!.updated_at = new Date().getTime();
                }
                //
                // START OF deleteCommentThread BLOCK
                //
                else if (isAnyOf(Actions.deleteCommentThread)(action)) {
                    if (!actionValid(action, ["commentThreadUid"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["comment_threads"], action)) {
                        return draftState;
                    }

                    const {commentThreadUid} = action.payload;
                    delete draftState.comment_threads[commentThreadUid];
                }
                //
                // START OF updateCommentThreadsPositionAnchor BLOCK
                //
                else if (isAnyOf(Actions.updateCommentThreadsPositionAnchor)(action)) {
                    if (
                        !actionValid(
                            action,
                            ["comment_threads"],
                            (action) => action.payload.comment_threads.length !== 0,
                        )
                    ) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["comment_threads"], action)) {
                        return draftState;
                    }

                    const {comment_threads} = action.payload;
                    const oldCommentThreads = draftState.comment_threads;

                    Object.values(oldCommentThreads).forEach((existingCommentThreadData) => {
                        const update = comment_threads.find((e) => e.uid === existingCommentThreadData.uid);

                        if (update) {
                            oldCommentThreads[existingCommentThreadData.uid]!.anchor = update.anchor;
                            oldCommentThreads[existingCommentThreadData.uid]!.updated_at = new Date().getTime();
                        }
                    });
                }
                //
                // START OF setCode BLOCK
                //
                else if (isAnyOf(Actions.setCode)(action)) {
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {code, hasErrors} = action.payload;

                    draftState.behaviour = code;
                    draftState.behaviour_has_errors = hasErrors;
                }
                //
                // START OF replaceElementPartVersionDataCache BLOCK
                //
                else if (isAnyOf(Actions.replaceElementPartVersionDataCache)(action)) {
                    if (!actionValid(action, ["elements"], (action) => action.payload.elements.length !== 0)) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements", "uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }

                    const {elements} = action.payload;
                    const oldElements = draftState.elements;

                    elements.forEach((updatedElementData) => {
                        oldElements[updatedElementData.uid]!.part_uid = updatedElementData.part_uid;
                        oldElements[updatedElementData.uid]!.part_version = updatedElementData.part_version;
                        oldElements[updatedElementData.uid]!.part_version_data_cache =
                            updatedElementData.part_version_data_cache;
                        oldElements[updatedElementData.uid]!.updated_at = new Date().getTime();
                    });

                    setElementsAndGenerateNodes(draftState, elements);

                    const pcbTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                    pcbTreeManager.updateNetNodes(draftState);

                    draftState.pcbLayoutNodes = pcbTreeManager.treeNodes as PcbNodesMap<AnyPcbNode>;
                }
                //
                // START OF setRole BLOCK
                //
                else if (isAnyOf(Actions.setRole)(action)) {
                    if (!actionValid(action, ["role"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["roles"], action)) {
                        return draftState;
                    }
                    const {role} = action.payload;
                    Document.setUserPermissions(draftState, role);
                }
                //
                // START OF removeRole BLOCK
                //
                else if (isAnyOf(Actions.removeRole)(action)) {
                    if (!actionValid(action, ["roleUid"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["roles"], action)) {
                        return draftState;
                    }
                    const {roleUid} = action.payload;
                    Document.removeUserPermissions(draftState, roleUid);
                }
                //
                // START OF setDocumentAsPrivate BLOCK
                //
                else if (isAnyOf(Actions.setDocumentAsPrivate)(action)) {
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }
                    Document.setPermissionsToPrivate(draftState);
                }
                //
                // START OF setProjectInvite BLOCK
                //
                else if (isAnyOf(Actions.setProjectInvite)(action)) {
                    if (!actionValid(action, ["invite"])) {
                        return draftState;
                    }
                    const {invite} = action.payload;
                    draftState.invites[invite.uid] = invite;
                }
                //
                // START OF removeProjectInvite BLOCK
                //
                else if (isAnyOf(Actions.removeProjectInvite)(action)) {
                    if (!actionValid(action, ["invite"])) {
                        return draftState;
                    }
                    const {invite} = action.payload;
                    delete draftState.invites[invite.uid];
                }
                //
                // START OF setOrganizationMemberPermissionType BLOCK
                //
                else if (isAnyOf(Actions.setOrganizationMemberPermissionType)(action)) {
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    draftState.organization_member_permission_type = action.payload.permissionType;
                    draftState.user_access_list = generateUserAccessListForDocument(
                        draftState,
                        values(draftState.roles),
                    );
                    draftState.has_anon_access = draftState.user_access_list.includes(ANON_ROLE_UID);
                }
                //
                // START OF setEnterpriseMemberPermissionType BLOCK
                //
                else if (isAnyOf(Actions.setEnterpriseMemberPermissionType)(action)) {
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    draftState.enterprise_member_permission_type = action.payload.permissionType;
                    draftState.user_access_list = generateUserAccessListForDocument(
                        draftState,
                        values(draftState.roles),
                    );
                    draftState.has_anon_access = draftState.user_access_list.includes(ANON_ROLE_UID);
                }
                //
                // START OF setDocumentConfigs BLOCK
                //
                else if (isAnyOf(Actions.setDocumentConfigs)(action)) {
                    if (!actionValid(action, ["configs"], (action) => action.payload.configs.length !== 0)) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["configs"], action)) {
                        return draftState;
                    }

                    const {configs} = action.payload;
                    const currentUser = action.payload.currentUser ?? AnonymousUser;
                    const oldConfigs = draftState.configs;

                    if (currentUser === AnonymousUser) {
                        return draftState;
                    }

                    configs.forEach((configData: IDocumentConfigDataWithoutIds) => {
                        if (configData.key && draftState) {
                            const uid = documentConfigUid({
                                key: configData.key,
                                section: configData.section,
                                user_uid: currentUser.uid,
                            });
                            const newConfig = documentConfigWithIds(configData, currentUser.uid);

                            if (!oldConfigs[uid]) {
                                oldConfigs[uid] = newConfig;
                            } else {
                                oldConfigs[uid] = {...oldConfigs[uid], ...newConfig};
                            }
                        }
                    });
                }
                //
                // START OF setSimulationControlsValue BLOCK
                //
                else if (isAnyOf(Actions.setSimulationControlsValue)(action)) {
                    if (!actionValid(action, ["elementId", "controlId", "value"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["configs"], action)) {
                        return draftState;
                    }

                    const section = "control_values";
                    const {elementId, controlId, value} = action.payload;
                    const currentUser = action.payload.currentUser ?? AnonymousUser;

                    if (currentUser === AnonymousUser) {
                        return draftState;
                    }

                    // NOTE: this key is used in multiple files
                    const key = `simulation_controls_${elementId}`;
                    const uid = documentConfigUid({key, section, user_uid: currentUser.uid});

                    const targetConfig = draftState.configs[uid];
                    if (targetConfig) {
                        targetConfig.value = {[controlId]: value};
                    } else {
                        draftState.configs[uid] = {
                            uid,
                            user_uid: currentUser.uid,
                            section,
                            key,
                            value: {[controlId]: value},
                        };
                    }
                }
                //
                // START OF revertActionRecords BLOCK
                //
                else if (isAnyOf(Actions.revertActionRecords, Actions.revertDocumentVersion)(action)) {
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }
                    const {actionRecords} = action.payload;
                    revertActionRecords(draftState, actionRecords);
                }
                //
                // START OF setDocumentDataFromSnapshot BLOCK
                //
                // NOTE: this is like setDocumentData but it creates a
                // latestPatch, and the latestPatch is size-optimized.
                //
                // NOTE: setDocumentDataFromSnapshot and restoreDocumentVersion do the
                // same state changes but they have different action flags for
                // behavior downstream.
                //
                else if (isAnyOf(Actions.setDocumentDataFromSnapshot, Actions.restoreDocumentVersion)(action)) {
                    const {documentData} = action.payload;

                    // Simple, two-level-deep diff to optimize patch size,
                    // similar to how autosave diffing works. It should take
                    // less than ~10ms for a 10,000-node document in the common
                    // case. See also conditionallyWriteCollection.
                    Object.entries(documentData).forEach(([key, docValue]) => {
                        const draftKey = key as keyof IDocumentData;

                        // HACK: latestPatch should always be re-created by
                        // withHistoryUpdate, otherwise we get an "Error
                        // generating PCB data"
                        if (draftKey === "latestPatch" || key === "latestActionRecordUid") {
                            return;
                        }

                        // HACK: ignore these because they are causing Firestore
                        // security rules permission errors in changing null to
                        // undefined for keys like "organization_owner_uid"
                        // QUESTION: is there a place where we drop nulls from
                        // being saved to Firestore?
                        if (docValue === null && draftState[draftKey] === undefined) {
                            return;
                        }

                        if (isPlainObject(docValue)) {
                            const docKeys: string[] = Object.keys(docValue);
                            const draftKeys: string[] = Object.keys(draftState[draftKey]);

                            for (const id of difference(draftKeys, docKeys)) {
                                delete draftState[draftKey][id];
                            }

                            for (const id of difference(docKeys, draftKeys)) {
                                draftState[draftKey][id] = docValue[id];
                            }

                            for (const id of intersectionWith(
                                docKeys,
                                draftKeys,
                                (a, b) => !isEqual(draftState[draftKey][a], docValue[b]),
                            )) {
                                draftState[draftKey][id] = docValue[id];
                            }
                        } else if (draftState[draftKey] !== docValue) {
                            // @ts-expect-error: draftState is a IDocumentData
                            draftState[draftKey] = docValue;
                        }
                    });
                }
                //
                // START OF setPartVersionDataCache BLOCK
                //
                else if (isAnyOf(Actions.setPartVersionDataCache, Actions.setHeadPartVersionDataCache)(action)) {
                    if (!actionValid(action, ["partUid", "partVersionData"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements", "uid", "pcbLayoutNodes"], action)) {
                        return draftState;
                    }
                    const {partUid, partVersionData} = action.payload;

                    const elementsToChange = Object.values(draftState.elements)
                        // NOTE: multiple elements in the doc may be from the same part
                        .filter(
                            (elementData) =>
                                // QUESTION: why compare to partUid and not partVersionData.part_uid?
                                elementData.part_uid === partUid &&
                                // NOTE: crazy HEAD version semantics
                                (elementData.part_version_data_cache.version !== partVersionData.version ||
                                    (partVersionData.version === headVersionName &&
                                        // HACK: this is slow but there is no
                                        // other reliable way to detect changes
                                        // in a HEAD version
                                        !isEqual(elementData.part_version_data_cache, partVersionData))),
                        );

                    if (!elementsToChange.length) {
                        return draftState;
                    }

                    elementsToChange.forEach((elementData) => {
                        // QUESTION: why does inheritPartProperties exist???
                        PartStorageHelper.inheritPartProperties(partVersionData, elementData);

                        /**
                         * Before calling `addElementsToDocument`, remove all the nodes under this element.
                         * It is safe to do so here because here we are updating a new part version for
                         * this element. In other words, we are removing all the nodes from the old part
                         * version, and re-generate a new sets of node based on the latest part version
                         * data.
                         *
                         * Here we manually craft the nodes to be removed:
                         * 1. First grab all nodes under the element node
                         * 2. Then filter out nodes that are not under the element's sublayout, meaning nodes that dont come from element's part version data
                         */

                        // TODO: No need to say `includeRoot: true` nor the filter?
                        const nodeUidsToRemove = getSubTreeUids({
                            pcbNodes: draftState.pcbLayoutNodes,
                            nodeUid: elementData.uid,
                            includeRoot: true,
                        }).filter((nodeUid) => PcbNodeHelper.isNodeInElementSubLayout(nodeUid, elementData.uid));

                        removeNodesAndDescendants(draftState.pcbLayoutNodes, nodeUidsToRemove);
                        draftState.elements[elementData.uid]!.part_version_data_cache = partVersionData;

                        setElementsAndGenerateNodes(draftState, [draftState.elements[elementData.uid]!]);
                    });
                }
                //
                // START OF mergeSubDocumentData BLOCK
                //
                else if (isAnyOf(Actions.mergeSubDocumentData)(action)) {
                    if (!actionValid(action, ["subDocumentDatas"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["subDocumentData"], action)) {
                        return draftState;
                    }

                    // NOTE: Normally, subDocumentData should be defined at this
                    // point but it doesn't hurt to update from an empty state
                    const subDocumentData = draftState.subDocumentData ?? {elements: {}, routeVertices: {}};
                    action.payload.subDocumentDatas.forEach((subDocumentDataUpdate) => {
                        Object.entries(subDocumentDataUpdate).forEach(([collectionName, collection]) => {
                            Object.assign(subDocumentData[collectionName as keyof ISubDocumentData], collection);
                        });
                    });

                    const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                    pcbDomTreeManager.updateNetNodes(draftState);
                }
                //
                // START OF deleteSubDocumentData BLOCK
                //
                // QUESTION: should we do the same logic in deleteSubjects
                // pre-emptively to avoid a double dispatch?
                //
                else if (isAnyOf(Actions.deleteSubDocumentData)(action)) {
                    if (!actionValid(action, ["elementUids"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["subDocumentData"], action)) {
                        return draftState;
                    }
                    const {subDocumentData} = draftState;
                    if (!subDocumentData) {
                        return draftState;
                    }
                    action.payload.elementUids.forEach((elementUid) => {
                        Object.values(subDocumentData).forEach((collection) => {
                            Object.keys(collection).forEach((nestedUid) => {
                                if (nestedUid.startsWith(elementUid)) {
                                    delete collection[nestedUid];
                                }
                            });
                        });
                    });

                    const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                    pcbDomTreeManager.updateNetNodes(draftState);
                }
                //
                // START OF startReceivingLatestDraftsForPartUid BLOCK
                //
                else if (isAnyOf(startReceivingLatestDraftsForPartUid)(action)) {
                    if (!actionValid(action, ["partUid"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const {partUid} = action.payload;
                    const elementDataForPart = Object.entries(draftState.elements).find((entry) => {
                        const [_elementUid, element] = entry;
                        return element.part_version_data_cache.part_uid === partUid;
                    });

                    if (!elementDataForPart) {
                        return draftState;
                    }

                    const [_elementUid, element] = elementDataForPart;
                    const currentPartVersion = element.part_version_data_cache.version;
                    const newEntry = {[partUid]: {revertToVersion: currentPartVersion}};

                    if (!draftState.receiveLatestDraftsForPartUids) {
                        draftState.receiveLatestDraftsForPartUids = newEntry;
                    }
                    // Add the part if we don't already have it
                    else {
                        draftState.receiveLatestDraftsForPartUids = {
                            ...draftState.receiveLatestDraftsForPartUids,
                            ...newEntry,
                        };
                    }
                }
                //
                // START OF stopReceivingLatestDraftsForPartUid BLOCK
                //
                else if (isAnyOf(stopReceivingLatestDraftsForPartUid)(action)) {
                    if (!actionValid(action, ["partUid"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }
                    const {partUid} = action.payload;
                    if (draftState.receiveLatestDraftsForPartUids) {
                        delete draftState.receiveLatestDraftsForPartUids[partUid];
                    }
                }
                //
                // START OF updatePlotsPosition BLOCK
                //
                else if (isAnyOf(Actions.updatePlotsPosition)(action)) {
                    if (!actionValid(action, ["elementId"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements"], action)) {
                        return draftState;
                    }

                    const {position, elementId, attached} = action.payload;

                    if (draftState.elements[elementId]!) {
                        const metamodule = draftState.elements[elementId]!.metamodule;
                        if (metamodule) {
                            if (metamodule.attached !== attached) {
                                metamodule.attached = attached;
                            }
                            if (
                                metamodule.position?.x !== position.x ||
                                metamodule.position?.y !== position.y ||
                                metamodule.position?.z !== position.z
                            ) {
                                metamodule.position = position;
                            }
                        } else {
                            draftState.elements[elementId]!.metamodule = {
                                position,
                                attached,
                                visibilityToggleMap: {},
                            };
                        }
                    }
                }
                //
                // START OF updatePropertyVisibility BLOCK
                //
                else if (isAnyOf(Actions.updatePropertyVisibility)(action)) {
                    if (!actionValid(action, ["elementIds", "propertyId", "visibility"])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, ["elements"], action)) {
                        return draftState;
                    }

                    const {elementIds, propertyId, visibility} = action.payload;

                    for (const elementId of elementIds) {
                        if (!draftState.elements[elementId]) continue;
                        const metamodule = draftState.elements[elementId]!.metamodule;
                        let property = draftState.elements[elementId]!.properties[propertyId];
                        if (!property) {
                            // During batch-edit, properties are identified by their name,
                            // not uid. Find the named property's uid.
                            property = getProperty(propertyId, draftState.elements[elementId]!.properties);
                        }
                        if (!property) continue;

                        if (metamodule) {
                            metamodule.visibilityToggleMap[property.uid] = visibility;
                        } else {
                            // The default property is considered true if undefined
                            const isDefaultProperty =
                                ElementHelper.getDefaultPartProperty(draftState.elements[elementId]!.properties)
                                    ?.uid === property.uid;

                            draftState.elements[elementId]!.metamodule = {
                                position: undefined,
                                attached: null,
                                visibilityToggleMap: {[property.uid]: isDefaultProperty ? false : true},
                            };
                        }
                    }
                }
                //
                // START OF undo BLOCK
                //
                else if (isAnyOf(Actions.undoDocument)(action)) {
                    if (!actionValid(action, [])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }

                    const latestChange = DocumentPatchManager.popLatestChangeFromUndoStack();

                    if (!latestChange) return;
                    // NOTE: It is important to NOT return anything here, because that will
                    // end up replacing the whole state.
                    // See detail: https://immerjs.github.io/immer/return/
                    // `applyPatches` mutates the state in place already
                    applyPatches(draftState, latestChange.patchesForRedux);
                }
                //
                // START OF redo BLOCK
                //
                else if (isAnyOf(Actions.redoDocument)(action)) {
                    if (!actionValid(action, [])) {
                        return draftState;
                    }
                    if (!stateValid(draftState, [], action)) {
                        return draftState;
                    }
                    const latestChange = DocumentPatchManager.popLatestChangeFromRedoStack();

                    if (!latestChange) return;

                    // NOTE: It is important to NOT return anything here, because that will
                    // end up replacing the whole state.
                    // See detail: https://immerjs.github.io/immer/return/
                    // `applyPatches` mutates the state in place already
                    applyPatches(draftState, latestChange.patchesForRedux);
                }
                //
                // START OF applyCommands BLOCK
                //
                else if (isAnyOf(applyCommands)(action)) {
                    const wiringOps: EnrichedConnectPinsCommand[] = [];
                    action.payload.commands.forEach((command) => {
                        switch (command.type) {
                            case "addElementCommand": {
                                const {element} = command;
                                setElementsAndGenerateNodes(draftState, [element]);
                                break;
                            }
                            case "connectPinsCommand": {
                                // We do all wiring ops together
                                wiringOps.push(command);
                                break;
                            }
                            case "setElementPropertyCommand": {
                                const element = draftState.elements[command.elementUid];
                                if (!element) {
                                    // TODO: do we throw here or just report?  It's probably a throw... we've violated an invariant
                                    FluxLogger.captureError(
                                        new Error(
                                            `Failed to apply command ${command.type} to update property for element ${command.elementUid} because element does not exist`,
                                        ),
                                    );
                                } else {
                                    // TODO: is this even right?  Also, would be nice to have a domain function for this.
                                    const existingProperty = Object.values(element.properties).find(
                                        (prop) => prop.name === command.propertyName,
                                    );
                                    if (existingProperty) {
                                        existingProperty.value = command.rawPropertyValue;
                                    } else {
                                        const newProperty: IPropertyData = {
                                            uid: guid(),
                                            name: command.propertyName,
                                            value: command.rawPropertyValue,
                                        };
                                        element.properties[newProperty.uid] = newProperty;
                                    }
                                }
                            }
                        }
                    });

                    if (wiringOps.length > 0) {
                        const portal = wiringOps[0]?.portal;
                        if (!portal) {
                            throw new Error(`runtime error - portal cannot be undefined!`);
                        }

                        const connections = wiringOps.map((op) => {
                            return {start: op.source, end: op.target};
                        });
                        costBasedAutoWireStrategy(draftState, action.payload.actingUser, {
                            // TODO: clean me up
                            portalPartVersion: portal,
                            connectionsToWire: connections,
                            elementToPortalDistance: 10,
                            portalStrategyCost: 100,
                        });
                        ensureRouteVertexState(draftState);

                        // Lastly we need to update the nets
                        const pcbDomTreeManager = new PcbTreeManager(draftState.pcbLayoutNodes, FluxLogger);
                        pcbDomTreeManager.updateNetNodes(draftState);
                    }
                }

                // NOTE: It is important for reducer blocks above to return the
                // draftState when there has been no change to avoid a new state
                // that only changes updated_at.
                // QUESTION: should we move this update of updated_at into
                // withHistoryUpdate like latestPatch so that only non-empty
                // changes update updated_at?
                //
                // TODO: This block of code leads to the anti-pattern mentioned in https://coda.io/d/Why-not-Service-Locator_dZhtNCBApM2/Why-not-Service-Locator_susQP#_luk0Q,
                // because this block requires a middleware to inject currentUser to
                // all redux action, just for convenience. And in that middleware, we are
                // using a service locator.
                // To avoid it, we can either
                // - factor it to each redux action with `updatesDocumentTimestamp` flag, and pass `currentUser` to those redux actions OR
                // - factor it to somewhere like an epic.
                // Easiest way to factor is the first idea I think
                //
                // QUESTION: why do we filter out bots here of all places?
                if (!currentAgentIsBot) {
                    const {payload, currentUser} = action;
                    if (payload && payload.updatesDocumentTimestamp && currentUser) {
                        if (!stateValid(draftState, [], action)) {
                            return draftState;
                        }

                        if (currentUser?.uid) {
                            const contributorExists = draftState.contributor_uids?.find(
                                (userUid) => userUid === currentUser.uid,
                            );

                            if (!contributorExists) {
                                if (!draftState.contributor_uids) {
                                    draftState.contributor_uids = emptyArray;
                                }

                                draftState.contributor_uids.push(currentUser.uid);
                                draftState.contributor_uids = draftState.contributor_uids.filter((uid) => uid);
                            }
                        }

                        const now = new Date().getTime();
                        if (now < draftState.created_at) {
                            // TODO: make this throw once we see it does not break too many legacy documents
                            FluxLogger.captureError(new Error("updated_at should never be less than created_at"));
                        }
                        if (now < draftState.updated_at) {
                            // TODO: make this throw once we see it does not break too many legacy documents
                            FluxLogger.captureError(
                                new Error("New updated_at should never be less than old updated_at"),
                            );
                        }

                        draftState.updated_at = now;
                        draftState.updated_by = currentUser.handle;
                    }

                    return draftState || null;
                }
            },
            action.patchCreationStrategy || "create",
        );
    }
};
export default documentReducer;
