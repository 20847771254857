import {Camera} from "@react-three/fiber";
import * as Three from "three";
import {create, StoreApi, UseBoundStore} from "zustand";

import {initialPartSearchRefinements, PartSearchRefinements} from "./models/PartSearchRefinements";

type LibrarySearchState = {
    query: string | undefined;
    refinements: PartSearchRefinements;
};

type DocumentUiState = {
    remoteMouseRef?: React.RefObject<Three.Mesh>;
    pcbCamera?: Camera;
    embedMode: boolean;
    shouldShowChangeHistoryPanel: boolean;
    librarySearch: LibrarySearchState;
    pcbObjectsFilter: string;
    schematicObjectsFilter: string;
    rulesFilter: string;
    hideIfNotStarredByCurrentUser: boolean;
    showAllPinLabels: boolean;
    showCollaborativeCursors: boolean;

    // Comment-related stuff
    selectedCommentThreadUid: string | undefined;
    newCommentMessage: string | undefined;
    showComments: boolean;
    projectChatPrefillMessage: string | undefined;
};

type DocumentUiApi = {
    // Note: this is only used once, for camera zoom - rethink the api?
    setPcbCamera: (camera: Camera) => void;

    setEmbedMode: (embedMode: boolean) => void;

    showChangeHistoryPanel: () => void;
    hideChangeHistoryPanel: () => void;

    documentUidChanged: () => void;

    setComponentBrowserSearch: (searchQuery: string | undefined) => void;
    updateComponentSearchRefinements: (update: Partial<PartSearchRefinements>) => void;
    clearComponentSearch: () => void;
    clearComponentSearchRefinements: () => void;

    setPcbObjectsFilter: (filter: string) => void;
    setSchematicObjectsFilter: (filter: string) => void;
    setRulesFilter: (filter: string) => void;

    setHideIfNotStarredByCurrentUser: (value: boolean) => void;
    setShowAllPinLabels: (show: boolean) => void;
    setShowComments: (show: boolean) => void;
    setShowCollaborativeCursors: (show: boolean) => void;

    // Comment-related stuff
    hideCommentThreads: () => void;
    showCommentThread: (commentThreadUid: string, prefillMessage?: string) => void;
    toggleCommentThread: (commentThreadUid: string) => void;
    prefillProjectChatMessage: (text: string) => void;
    removeProjectChatPrefill: () => void;
};

type DocumentUiStoreState = DocumentUiState & DocumentUiApi;

export type UseDocumentUiStore = UseBoundStore<StoreApi<DocumentUiStoreState>>;

export const createUseDocumentUiStoreHook = () =>
    create<DocumentUiStoreState>((set) => ({
        setPcbCamera: (camera) =>
            set((state) => ({
                ...state,
                pcbCamera: camera,
            })),
        embedMode: false,
        setEmbedMode: (embedMode) => {
            set((state) => {
                return {...state, embedMode: embedMode};
            });
        },
        librarySearch: {
            query: undefined,
            refinements: initialPartSearchRefinements,
        },
        setComponentBrowserSearch: (searchQuery) => {
            set((state) => {
                const newLibrarySearch = {refinements: state.librarySearch.refinements, query: searchQuery};
                return {...state, librarySearch: newLibrarySearch};
            });
        },
        updateComponentSearchRefinements: (update) => {
            set((state) => {
                const newRefinements = {...state.librarySearch.refinements, ...update};
                const newLibrarySearch = {query: state.librarySearch.query, refinements: newRefinements};
                return {...state, librarySearch: newLibrarySearch};
            });
        },
        clearComponentSearchRefinements: () => {
            set((state) => {
                const newLibrarySearch = {query: state.librarySearch.query, refinements: initialPartSearchRefinements};
                return {...state, librarySearch: newLibrarySearch};
            });
        },
        clearComponentSearch: () => {
            set((state) => {
                const newLibrarySearch = {query: "", refinements: initialPartSearchRefinements};
                return {...state, librarySearch: newLibrarySearch};
            });
        },
        pcbObjectsFilter: "",
        setPcbObjectsFilter: (filter) => {
            set((state) => ({
                ...state,
                pcbObjectsFilter: filter,
            }));
        },
        schematicObjectsFilter: "",
        setSchematicObjectsFilter: (filter) => {
            set((state) => ({
                ...state,
                schematicObjectsFilter: filter,
            }));
        },
        rulesFilter: "",
        setRulesFilter: (filter) => {
            set((state) => ({
                ...state,
                rulesFilter: filter,
            }));
        },
        shouldShowChangeHistoryPanel: false,
        showChangeHistoryPanel: () => {
            set((state) => {
                return {...state, shouldShowChangeHistoryPanel: true};
            });
        },
        hideChangeHistoryPanel: () => {
            set((state) => {
                return {...state, shouldShowChangeHistoryPanel: false};
            });
        },
        documentUidChanged: () => {
            set((state) => {
                return {
                    ...state,
                    selectedCommentThreadUid: undefined,
                    shouldShowChangeHistoryPanel: false,
                };
            });
        },
        hideIfNotStarredByCurrentUser: false,
        setHideIfNotStarredByCurrentUser: (value: boolean) => {
            set((state) => {
                return {
                    ...state,
                    hideIfNotStarredByCurrentUser: value,
                };
            });
        },
        showAllPinLabels: false,
        setShowAllPinLabels: (show: boolean) => {
            set((state) => ({...state, showAllPinLabels: show}));
        },
        showCollaborativeCursors: true,
        setShowCollaborativeCursors: (show: boolean) => {
            set((state) => {
                return {...state, showCollaborativeCursors: show};
            });
        },

        // Comment-related stuff

        selectedCommentThreadUid: undefined,
        newCommentMessage: undefined,
        hideCommentThreads: () => {
            set((state) => {
                return {...state, selectedCommentThreadUid: undefined, newCommentMessage: undefined};
            });
        },
        showCommentThread: (commentThreadUid: string, preFillMessage?: string) => {
            set((state) => {
                if (state.selectedCommentThreadUid === commentThreadUid) {
                    return {...state};
                } else {
                    return {...state, selectedCommentThreadUid: commentThreadUid, newCommentMessage: preFillMessage};
                }
            });
        },
        toggleCommentThread: (commentThreadUid: string) => {
            set((state) => {
                if (state.selectedCommentThreadUid === commentThreadUid) {
                    return {...state, selectedCommentThreadUid: undefined};
                } else {
                    return {...state, selectedCommentThreadUid: commentThreadUid, newCommentMessage: undefined};
                }
            });
        },
        showComments: true,
        setShowComments: (show: boolean) => {
            set((state) => {
                return {...state, showComments: show};
            });
        },
        projectChatPrefillMessage: undefined,
        prefillProjectChatMessage: (text) => {
            set((state) => ({...state, projectChatPrefillMessage: text}));
        },
        removeProjectChatPrefill: () => set((state) => ({...state, projectChatPrefillMessage: undefined})),
    }));
