import {IElementTerminalBareData, Orientation} from "@buildwithflux/core";
import {IVector2, IVertexObject} from "@buildwithflux/models";
import {guid} from "@buildwithflux/shared";

export default class Vertex {
    public readonly position: IVector2;
    public connectedVertices: Array<{vertex: Vertex; type: Orientation}>;
    public id: string;
    public isSaved: boolean;
    public terminal: IElementTerminalBareData | null;
    constructor(position: IVector2, id?: string, terminalData?: IElementTerminalBareData) {
        this.position = {...position};
        this.connectedVertices = [];
        this.isSaved = id ? true : false;
        this.id = id || guid();
        this.terminal = terminalData ? {...terminalData} : null;
    }
    public serialize(): IVertexObject {
        return {
            id: this.id,
            position: {...this.position},
            connectedVertices: Object.fromEntries(this.connectedVertices.map(({vertex, type}) => [vertex.id, type])),
            terminal: this.terminal,
        };
    }
}
