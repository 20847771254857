/**
 * These are the little icon filters a user can toggle, below the part search
 * text input.
 */
export enum PartSearchRefinement {
    organization = "organization",
    owner = "owner",
    starred = "starred",
    footprint = "footprint",
    layout = "layout",
    simulationModel = "simulationModel",
    datasheet = "datasheet",
    mpn = "mpn",
    threeDModel = "threeDModel",
    distributors = "distributors",
    partTypes = "partTypes",
    mounts = "mounts",
    packageCaseCodes = "packageCaseCodes",
    terminalCounts = "terminalCounts",
    compliance = "compliance",
    manufacturers = "manufacturers",
    resistances = "resistances",
    tolerances = "tolerances",
    powerRatings = "powerRatings",
    resistorTypes = "resistorTypes",
    capacitances = "capacitances",
    voltageRatings = "voltageRatings",
    capacitorTolerances = "capacitorTolerances",
    capacitorTypes = "capacitorTypes",
    inductances = "inductances",
    currentRatings = "currentRatings",
    inductorTolerances = "inductorTolerances",
    inductorShieldings = "inductorShieldings",
    inductorTypes = "inductorTypes",
    icTypes = "icTypes",
    operatingVoltages = "operatingVoltages",
    powerConsumptions = "powerConsumptions",
    frequencies = "frequencies",
    logicFunctions = "logicFunctions",
    transistorTypes = "transistorTypes",
    drainToSourceVoltages = "drainToSourceVoltages",
    continuousDrainCurrents = "continuousDrainCurrents",
    diodeTypes = "diodeTypes",
    reverseVoltages = "reverseVoltages",
    forwardVoltages = "forwardVoltages",
    connectorTypes = "connectorTypes",
    genders = "genders",
    pitchs = "pitchs",
    positions = "positions",
    rows = "rows",
}

export type PartSearchRefinements = {
    organization: boolean;
    owner: boolean;
    starred: boolean;
    footprint: boolean;
    layout: boolean;
    mpn: boolean;
    distributors: string[];
    partTypes: string[];
    mounts: string[];
    packageCaseCodes: string[];
    terminalCounts: string[];
    compliance: string[];
    manufacturers: string[];
    resistances: string[];
    tolerances: string[];
    powerRatings: string[];
    resistorTypes: string[];
    capacitances: string[];
    voltageRatings: string[];
    capacitorTolerances: string[];
    capacitorTypes: string[];
    inductances: string[];
    currentRatings: string[];
    inductorTolerances: string[];
    inductorShieldings: string[];
    inductorTypes: string[];
    icTypes: string[];
    operatingVoltages: string[];
    powerConsumptions: string[];
    frequencies: string[];
    logicFunctions: string[];
    transistorTypes: string[];
    drainToSourceVoltages: string[];
    continuousDrainCurrents: string[];
    diodeTypes: string[];
    reverseVoltages: string[];
    forwardVoltages: string[];
    connectorTypes: string[];
    genders: string[];
    pitchs: string[];
    positions: string[];
    rows: string[];
};

/**
 * The initial filter state, when a document has just been loaded (i.e. opened
 * a project page).
 */
export const initialPartSearchRefinements: PartSearchRefinements = {
    organization: false,
    owner: false,
    starred: false,
    footprint: false,
    layout: false,
    mpn: false,
    distributors: [],
    partTypes: [],
    mounts: [],
    packageCaseCodes: [],
    terminalCounts: [],
    compliance: [],
    manufacturers: [],
    resistances: [],
    tolerances: [],
    powerRatings: [],
    resistorTypes: [],
    capacitances: [],
    voltageRatings: [],
    capacitorTolerances: [],
    capacitorTypes: [],
    inductances: [],
    currentRatings: [],
    inductorTolerances: [],
    inductorShieldings: [],
    inductorTypes: [],
    icTypes: [],
    operatingVoltages: [],
    powerConsumptions: [],
    frequencies: [],
    logicFunctions: [],
    transistorTypes: [],
    drainToSourceVoltages: [],
    continuousDrainCurrents: [],
    diodeTypes: [],
    reverseVoltages: [],
    forwardVoltages: [],
    connectorTypes: [],
    genders: [],
    pitchs: [],
    positions: [],
    rows: [],
};
