import {Orientation} from "@buildwithflux/core";

import {WireConnectionPoint, WireProjectionAlgorithm, WireProjectionVertex} from "./Wiring";

const {HORIZONTAL, VERTICAL} = Orientation;

export class WireProjectionSimple implements WireProjectionAlgorithm {
    public project(from: WireConnectionPoint, to: WireConnectionPoint): WireProjectionVertex[] {
        return [
            {position: from.position},
            {direction: HORIZONTAL, position: {...to.position}},
            {direction: VERTICAL, position: {...to.position}},
        ];
    }
}
