// This is the entry point from the main thread into calling PcbLayoutEngine inside a web worker.
// Data flows is: PcbLayoutEngineInMain -> PcbLayoutEngineInWorker -> PcbLayoutEngine

// The current document state is maintained in the worker in PcbLayoutEngineInWorker. It is expected
// to be updated by calls to applyPcbLayoutEnginePatches() with ImmerJS patches from main thread
// store updates.

// In this way, the full document state is only copied once into the web worker and it is kept up to
// date. Benchmarking shows that copying the full document state takes around 10ms for a 800
// component doc whereas copying a list of patches and applying them takes close to 0ms typically.

import {FeatureFlagValues, PcbDocumentState, PcbLayoutEngine} from "@buildwithflux/core";
import {AutoLayoutData} from "@buildwithflux/models";
import {Remote} from "comlink";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Comlink from "comlink-loader-webpack5!./pcbLayoutEngineInWorker";
import {Patch} from "immer";

let initialized = false;
const comlink = new Comlink();

// Creates a new PcbLayoutEngine instance but reuses the document state from the previous instance.
// We use `applyPcbLayoutEnginePatches()` to modify the persistant document state instance that
// lives inside of the web worker.
export async function createPcbLayoutEngineWorker(
    documentState: PcbDocumentState,
    featureFlags: FeatureFlagValues,
    autoLayoutData: AutoLayoutData | undefined,
): Promise<Remote<PcbLayoutEngine>> {
    if (!initialized) {
        comlink.setInitialDocumentState(documentState);
        comlink.setFeatureFlags(featureFlags);
        initialized = true;
    }

    // We need to update auto-layout data every time
    comlink.setAutoLayoutData(autoLayoutData);

    // We create a proxy to avoid communicating twice with the worker — first time to
    // create the instance and second time to call the instance method.
    // Before:
    // - main thread calls createPcbLayoutEngineWorker()
    // - worker thread returns a new PcbLayoutEngine instance
    // - main thread calls bakeAllNodes()
    // - worker returns result of bakeAllNodes()
    // Now:
    // - main thread calls createPcbLayoutEngineWorker() and we return a proxy
    // - main thread calls bakeAllNodes()
    // - worker thread returns result of bakeAllNodes()
    return new Proxy<Remote<PcbLayoutEngine>>({} as Remote<PcbLayoutEngine>, {
        get(_target, prop: keyof PcbLayoutEngine) {
            if (typeof PcbLayoutEngine.prototype[prop] === "function") {
                return (...args: unknown[]) => {
                    return comlink.callPcbLayoutEngine(prop, args);
                };
            } else {
                return comlink.callPcbLayoutEngine(prop, []);
            }
        },
    });
}

/**
 * Sets `initialized` flag to false so that next time we will re-initialize
 * `documentState` when loading another project
 *
 * QUESTION: Should we make all of these a state machine?
 */
export function resetPcbDocumentStateInWebWorker() {
    initialized = false;
}

export function applyPcbLayoutEnginePatches(patches: Patch[]): Promise<any> {
    return comlink.applyPcbLayoutEnginePatches(patches);
}
